<template>
  <el-container>
    <el-aside class="f40">
      <img src="@/assets/img/logo.png" >咨询工具
    </el-aside>
    <!-- 用手机号找回密码 -->
    <el-main>
      <div class="flex-b-">
        <div>
          <div class="f48 text_left bold">找回密码</div>
        </div>
      </div>
      <el-form :model="ruleForm" status-icon ref="ruleForm" class="demo-ruleForm">
        <el-form-item>
          <i class="icon-user blue f20 mr-15"></i> <span class="f18">账号</span>
          <el-input v-model="account" placeholder="请输入手机号" clearable class="f20 mt-10"></el-input>
        </el-form-item>
        <el-form-item>
          <i class="icon-mima blue f20 mr-15"></i> <span class="f18">密码</span>
          <el-input type="password"  v-model="pwd" placeholder="请输入密码" clearable autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <i class="icon-mima blue f20 mr-15"></i> <span class="f18">再次输入密码</span>
          <el-input type="password"  v-model="pwd2" placeholder="请再次输入密码" clearable autocomplete="off"></el-input>
        </el-form-item>
        <div class="mt-100">
          <el-button class="mt-10" type="primary" @click="login">确定</el-button>
        </div>
      </el-form>
    </el-main>
  </el-container>
</template>

<script >
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'

export default {
  name:'ResetPwd',
  setup(){
    // data
    const route = useRoute()
    const router = useRouter()
    console.log(route.params.id)

    let account = ref('')
    let pwd = ref('')
    let pwd2 = ref('')


    // methods
    function login(){
      if(account.value && pwd.value && pwd2.value){
        if(pwd.value === pwd2.value){
          router.push({
            path: '/change',
            // query: {name:'', psw:''}
          })
        } else{
          alert('两次密码输入不一样，请重新输入密码')
        }
      } else if( ! account.value ){
        alert('请输入手机号')
      } else if( !pwd.value ){
        alert('请输入密码')
      }else if( !pwd2.value ){
        alert('请再次输入密码')
      }
      return router.push('/login')
    }


    return{
      account, pwd, pwd2, 
      
      login

    }
  },
}
</script>

<style scoped>
@import url(@/assets/css/reset.css);
  .el-aside {
    background: url(@/assets/img/login/login.png) no-repeat left top/100% 100vh;
    width: 61.15vw;
    padding-left:3.4vw;
    padding-top:4.17vw;
    font-family: PingFang-SC-Bold;
  }
  .el-aside img{
    width:3.4vw;
    margin-right:12px;
  }
  .el-main {
    padding: 4.17vw 4.17vw 0 3.39vw;
    height:100vh;
  }
  .f48{
    font-family: PingFang SC;
    line-height:1.2; 
  }
  .flex-b- .f16{
    color:#9D9FA6;
    margin-top:10px;
  }
  .flex-b- .f16::before{
    content:'';
    width: 3px;
    height: 14px;
    display: inline-block;
    background: #9D9FA6;
    margin-right: 8px;
    vertical-align: middle;
  }
  .flex--c span{
    background: url(@/assets/img/login/login_txtbg.png) no-repeat center center/100% 100%;
    background: #E5F1FE; 
    border:1px solid #0077FA;
    border-radius:5px; 
    display: inline-block;
    padding:5px 10px 5px 6px;
    position:relative;
  }
  .flex--c span:before{
    content:'';
    position:absolute;
    width:0;
    height:0;
    top:calc(50% - 6px);
    right:-8px;
    border-left: 8px solid #0077FA;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    z-index: 1;
  }
  .flex--c span::after{
    content:'';
    position:absolute;
    width:0;
    height:0;
    top:calc(50% - 6px);
    right:-7px;
    border-left: 8px solid #E5F1FE;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    z-index: 2;
  }
  .flex--c img{
    width: 6.25vw; 
    height:6.25vw;
  }
  .el-form{
    margin-top:60px;
  }
  .el-form img{
    width:18.75vw;
    height:18.75vw;
    padding:1vw;
    border:1px solid #CFD2E5; 
    border-radius:10px;
  }
  .el-form img+i{
    display: block;
    font-size: 30px;
    color: #ECF1FA;
    margin-top:5.2vw;
  }
  .el-form-item{
    margin-bottom:0;
    margin-top:50px;
  }
  i.f20{
    font-size: 20px;
  }
  .f18{
    color: #161823;
  }
  .el-input{ 
    height:56px;
  }
  :deep(.el-input__wrapper){
    padding-left:40px;
    font-size:18px;
    border-radius:8px;
  }
  :deep(.el-input__wrapper.is-focus){
    border:1px solid #CFD2E5;
    box-shadow: 0px 0px 10px 1px rgba(1,27,70,0.1) !important; 
  }
  .pab{
    color: #3061F2;
    right:20px; 
    bottom:12px;
  }
  .pab:before{
    content:'';
    width:1px;
    height:32px;
    background:#CFD2E5;
    position:absolute;
    bottom:0;
    left: -20px;
  }
  .f14.text_center{
    margin-top:100px;
  }
  .f14.mt-10{
    margin-top:10px;
  }
  .el-button{ 
    width: 100%; 
    height:3.75vw;
    background:#0077fa;
    font-size: 20px;
    margin-top:10px;
  }
</style>