<!-- 设备清单 -->
<template>
  <RightSide>
    <template v-slot:tit>设备清单</template>
    <template v-slot:write_msg>
      <el-form ref="addressForm" label-width="80px">
        <div class="flex">
          <span>办公设备：</span>
          <div class="text">
            <!--<el-checkbox-group v-model="office_equipment_ids">
              <el-checkbox v-for="(it,k) in office_equipment_list" :key="k" :label="it.id">{{it.name}}</el-checkbox>
            </el-checkbox-group>-->
            <el-button @click="addOfficeEquipment" class="addbtn" style="width: 100px; font-size: 14px; margin-bottom: 10px;">添加办公设备</el-button>
            <el-table :data="office_equipment_list" border show-header>
              <el-table-column label="名 称" width="150" align="center">
                <template #default="scope">
                  {{scope.row.name}}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="品牌型号" width="">
                <template #default="scope">
                  <el-input v-model="scope.row.model_parameter" placeholder="请输入品牌"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="content" label="数量" width="100" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.product_num" placeholder="数量"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="age" fixed="right" label="操作" width="150" align="center">
                <template #default="scope">
                  <i class="red icon-jianqu" @click="deleteTr(scope.$index, 'office_equipment')"></i>
                </template>
              </el-table-column>
            </el-table>
            <div class="f14 red" style="clear:both;">注：根据情况选择办公设备，品牌型号参考设备说明书即可，数量根据实际拥有数量进行填写。</div>
          </div>
          <SearchOfficeEquipment ref="visibleOfficeDialog" @setOfficeEquipment="setOfficeEquipment" @deleteOfficeEquipment="deleteOfficeEquipment" />
        </div>
        <div class="flex" v-show="show_flag">
          <span>生产设备：</span>
          <div class="text">
            <el-button @click="addEquipment" class="addbtn" style="width: 80px; font-size: 14px; margin-bottom: 10px;">添加设备</el-button>
            <el-table :data="production_equipment_list" border show-header>
              <el-table-column label="名 称" width="150" align="center">
                <template #default="scope">
<!--                  <el-input v-model="scope.row.name" placeholder="请输入名称" disabled></el-input>-->
                  {{scope.row.name}}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="型号参数" width="">
                <template #default="scope">
                  <el-input v-model="scope.row.model_parameter" placeholder="请输入型号参数"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="content" label="数量" width="100" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.product_num" placeholder="数量"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="age" fixed="right" label="操作" width="150" align="center">
                <template #default="scope">
                  <i class="red icon-jianqu" @click="deleteTr(scope.$index, 'production_equipment')"></i>
<!--                  <i class="blue icon-tianjia" @click="addTr(scope.$index, 'production_equipment')"></i>-->
                </template>
              </el-table-column>
            </el-table>
            <div class="f14 red">注：请填写生产过程中所用到的设备</div>
          </div>
          <SearchEquipment ref="visibleDialog" @setEquipment="setEquipment" @deleteEquipment="deleteEquipment" />
        </div>
        <div class="flex" v-show="show_flag">
          <span>监测设备：</span>
          <div class="text">
            <el-table :data="monitor_equipment_list" border show-header>
              <el-table-column label="名 称" width="150" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.name" placeholder="请输入名称"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="型号参数" width="">
                <template #default="scope">
                  <el-input v-model="scope.row.model_parameter" placeholder="请输入型号参数"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="content" label="数量" width="100" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.product_num" placeholder="请输入数量"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="age" fixed="right" label="操作" width="150" align="center">
                <template #default="scope">
                  <i class="red icon-jianqu" @click="deleteTr(scope.$index, 'monitor_equipment')"></i>
                  <i class="blue icon-tianjia" @click="addTr(scope.$index, 'monitor_equipment')"></i>
                </template>
              </el-table-column>
            </el-table>
            <div class="f14 red">注：请填写生产过程中所用到的监视测量设备</div>
          </div>
        </div>
      </el-form>
    </template>
    <template v-slot:foot_msg>
      <button @click="saveEquipment" style="float:right;width:100px;">保 存</button>
    </template>
  </RightSide>
</template>
<script>
import {reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import SearchEquipment from "@/components/common/SearchEquipment.vue";
import SearchOfficeEquipment from "@/components/common/SearchOfficeEquipment.vue";

export default {
  name: 'EquipmentSchedule',
  components: {
    SearchOfficeEquipment,
    RightSide,
    SearchEquipment,
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    //公共数据
    var project_id = Api.sessionGet('project_id');
    const show_flag = ref(false);
    const visibleDialog = ref(false);
    const visibleOfficeDialog = ref(false);
    let _this = reactive({
      action: Api.UPLOAD_URL,
      site: Api.WEB_SITE,
      project_id: project_id,
      production_equipment_list: [],
      monitor_equipment_list: [],
      office_equipment_list: [],
      //office_equipment_ids: [],
    });
    onMounted(() => {
      getEquipmentList()
    })
    //获取设备信息
    const getEquipmentList = () => {
      proxy.$post('company/getEquipmentList').then(res => {
        if (res.code == 0) {
          _this.production_equipment_list = res.data.production_equipment_list;
          _this.monitor_equipment_list = res.data.monitor_equipment_list;
          _this.office_equipment_list = res.data.office_equipment_list;
          //_this.office_equipment_ids = res.data.office_equipment_ids;
          if (res.show_flag == 1) {
            show_flag.value = true;
          }
        }else {
          Api.msg(res.msg);
        }
      })
    }
    //更新设备信息
    const saveEquipment = () => {
      let production_equipment_list = _this.production_equipment_list
      let monitor_equipment_list = _this.monitor_equipment_list
      let office_equipment_list = _this.office_equipment_list
      proxy.$post('company/saveEquipment', {
        'production_equipment_list':production_equipment_list,
        'monitor_equipment_list':monitor_equipment_list,
        'office_equipment_list':office_equipment_list,
        //'office_equipment_ids':_this.office_equipment_ids,
      }).then(res => {
        Api.msg(res.msg, 0, '', function () {
          if (res.code == 0) {
            resetPercent()
          }
        })
      })
    }
    //删除
    const deleteTr = (index, type)=>{
      if (type == 'production_equipment') {
        _this.production_equipment_list.splice(index, 1)
      }else if (type == 'monitor_equipment') {
        if (index!=0){
          _this.monitor_equipment_list.splice(index, 1)
        }
      }else if (type == 'office_equipment') {
        _this.office_equipment_list.splice(index, 1)
      }
    }
    //添加
    const addTr = (index, type)=>{
      var newTr = {id:0, name:'', model_parameter:'', product_num:0}
      if (type == 'production_equipment') {
        _this.production_equipment_list.splice(index+1, 0, newTr)
      }else {
        _this.monitor_equipment_list.splice(index+1, 0, newTr)
      }
    }
    // 添加设备
    const addEquipment = () => {
      visibleDialog.value.dialogVisble = true;
    }
    // 设置设备列表
    const setEquipment = (param) => {
      let containsObject = _this.production_equipment_list.some(item =>item.name === param.name);
      if(!containsObject) {
        _this.production_equipment_list.push(param);
      }
    }
    // 删除设备
    const deleteEquipment = (param) => {
      let arr = _this.production_equipment_list.filter(item => item.name !== param.name);
      _this.production_equipment_list = arr;
    }
    // 添加办公设备
    const addOfficeEquipment = () => {
      visibleOfficeDialog.value.dialogVisble = true;
    }
    const setOfficeEquipment = (param) => {
      let containsObject = _this.office_equipment_list.some(item =>item.name === param.name);
      if(!containsObject) {
        _this.office_equipment_list.push(param);
      }
    }
    const deleteOfficeEquipment = (param) => {
      let arr = _this.office_equipment_list.filter(item => item.name !== param.name);
      _this.office_equipment_list = arr;
    }
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -7;
      context.emit("updatePercent", data)
    }
    return {
      ...toRefs(_this),show_flag,visibleDialog,visibleOfficeDialog,
      deleteTr,addTr,addEquipment,setEquipment,deleteEquipment,
      addOfficeEquipment,setOfficeEquipment,deleteOfficeEquipment,
      saveEquipment,resetPercent,
    }
  }
}
</script>

<style scoped>
/* 中间部分 */
form {
  margin-top: 24px;
  margin-bottom: 24px;
}

form > .flex + div {
  margin-top: 16px;
}

.input {
  background: #F5F6FA;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
}

.input + div {
  margin-left: 12px;
  background: rgba(48, 97, 242, .1);
  color: var(--color-text-blue);
  font-size: 16px;
  width: 5em;
  cursor: pointer;
}

.input + div i {
  display: inline-block;
  transition: transform .3s;
}

.trans180 {
  transform: rotate(180deg);
  transition: transform .3s;
}

.input .el-select {
  width: 100px;
  height: 24px;
  line-height: 24px;
  border-right: 1px solid #9D9FA6;
  margin-top: -5px;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  --el-select-border-color-hover: transparent;
}

.input :deep(.el-input) {
  line-height: 24px;
  height: 24px;
  border-color: transparent;
}

.input :deep(.el-input__wrapper) {
  background: transparent;
  box-shadow: none;
}

.input :deep(.el-select .el-input.is-focus .el-input__wrapper),
.input :deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: none !important;
}

.input input {
  height: 36px;
  line-height: 36px;
  border: none;
  background: transparent;
  padding-left: 1.19vw;
}

.input i {
  width: 50px;
  height: 36px;
  font-size: 22px;
  background: var(--color-text-blue);
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

ul {
  height: 420px;
  overflow: hidden;
  margin: 15px 0;
}

li {
  background: #F5F6FA;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
  padding: 0 6px 0 18px;
  box-sizing: border-box;
}

.tit {
  width: 65%;
  font-size: 16px;
  font-family: 'PingFang-SC-Medium';
}

.num {
  width: calc(35% - 48px);
  font-size: 14px;
  font-family: 'PingFang-SC-Medium';
}

.addbtn {
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  margin-top: 6px;
  margin-left: 5px;
}
.addbtn:hover{
  color: #0a84ff !important;
  border-color: var(--color-text-blue);
  border: 1px solid var(--color-text-blue) !important;
}
.addbtn:focus{
  background: var(--color-text-blue) !important;
  color: #fff !important;
  border: 1px solid var(--color-text-blue) !important;
}

.picked .addbtn {
  background: rgba(48, 97, 242, .2);
}

/* 下 */
.foot_msg button {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: 'PingFang-SC-Bold';
  color: var(--color-text-white);
  background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
  border-radius: 4px;
  border: none;
  width: 200px;
  margin: calc((4.1vw - 40px) / 2) 0;
}

/* 弹出框 */
ol {
  margin: 15px 0;
}

.cont li.picked .addbtn {
  background: var(--color-text-blue);
}

.blue.hand {
  border: 0;
  background: none;
}

.flex > span {
  font-size: 14px;
  color: #6D6E73;
  margin-right: 10px;
  line-height: 32px;
}
/* 表格 */
.text{
  width: calc(90% - 4em - 10px);
  font-size: 14px;
}
:deep(.el-table th.el-table__cell){
  background:#eaeffe;
  color:#161823;
  border-color: #DADCE5;
  border-bottom:transparent;
}
:deep(.el-table.is-scrolling-none th.el-table-fixed-column--right){
  background:#eaeffe;
}
:deep(.el-input__wrapper){
  border:none;
  box-shadow:none;
}
:deep(.el-table) input{
  border:none;
  height:25px;
  font-size:14px;
  color:#3D3D40;
}
:deep(.el-input__wrapper):hover,
:deep(.el-input__wrapper.is-focus){
  box-shadow:none;
}
:deep(.el-table) i+i{
  margin-left:10px;
}
:deep(.el-table) i.red{
  border:1px solid #FE2C55;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}
:deep(.el-table) i.blue{
  border:1px solid #3061F2;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}

:deep(.el-dialog__headerbtn .el-dialog__close){
  color: #ff0000 !important;
  font-size: 20px !important;
}
</style>
