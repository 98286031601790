<!-- 顾客管理 -->
<template>
  <RightSide>
    <template v-slot:tit>顾客管理</template>
    <template v-slot:write_msg>
      <div class="flex_1 flex--c mt-10 mb-10">
        <span class="btn" style="cursor: pointer;" @click="addCustomer">添加顾客信息</span>
      </div>
      <el-table :data="customer_list" stripe style="width: 100%">
        <el-table-column prop="customer_name" label="顾客名称" width="220"> </el-table-column>
        <el-table-column prop="product_list" label="产品信息" width="120">
          <template #default="scope">
            <el-popover
                placement="top"
                title="产品信息"
                width="350"
                trigger="click"
            >
              <template #reference>
                <div style="color: #0a84ff; cursor: pointer;">查看详情</div>
              </template>
              <el-table :data="scope.row.product_list">
                <el-table-column width="150" property="product_name" label="产品名称" />
                <el-table-column width="100" property="product_model" label="规格型号" />
                <el-table-column width="100" property="product_num" label="数 量">
                  <template #default="scope">
                    <span>{{ scope.row.product_num }}{{ scope.row.product_unit }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="sale_date" label="销售日期" width="120"></el-table-column>
        <el-table-column prop="contacts" label="联系人" width="80"></el-table-column>
        <el-table-column prop="contact_phone" label="联系电话" width="120"></el-table-column>
        <el-table-column label="操作" key="slot" align="left" width="130">
          <template #default="scope">
            <el-button @click="editCustomer(scope.row)" style="margin-left: 3px; font-size:14px; width: 50px;">编 辑</el-button>
            <el-button @click="handleDelete(scope.row)" style="color: red;margin-left: 3px; font-size:14px; width: 50px;">删 除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--管理对话框-->
      <el-dialog v-model="visibleDialog" title="顾客信息" @close="resetPercent();" style="width: 800px;" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="false">
        <template #default>
          <el-form label-width="80px" :model="customer_form">
            <el-form-item label="顾客名称">
              <el-input v-model="customer_form.customer_name" placeholder="请输入顾客名称" autocomplete="off" style="width:300px;"></el-input>
              <div class="red">&nbsp;*</div>
            </el-form-item>
<!--            <el-form-item label="产品名称">
              <el-input v-model="customer_form.product_name" placeholder="请输入产品名称" autocomplete="off" style="width:300px;"></el-input>
              <div class="red">&nbsp;*</div>
            </el-form-item>
            <el-form-item label="销售数量">
              <el-input v-model="customer_form.sale_num" placeholder="请输入销售数量" autocomplete="off" style="width:120px;"></el-input>
              <div class="red">&nbsp;*</div>
&lt;!&ndash;              <el-input-number v-model="customer_form.sale_num" :min="1" autocomplete="off" style="width:120px;" />&ndash;&gt;
            </el-form-item>-->
            <el-form-item label="产品信息"><div class="red">&nbsp;*</div>
              <el-table :data="product_list" border show-header>
                <el-table-column label="产品名称" width="230" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.product_name" placeholder="请输入产品名称"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="规格型号" width="150">
                  <template #default="scope">
                    <el-input v-model="scope.row.product_model" placeholder="请输入型号参数"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="product_num" label="数量" width="90" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.product_num" placeholder="数量"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="product_unit" label="单位" width="100" align="center">
                  <template #default="scope">
<!--                    <el-select v-model="scope.row.product_unit" @change="handChange">-->
                    <el-select v-model="scope.row.product_unit">
                      <el-option
                          v-for="item in selected_options"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                      />
                    </el-select>
                    <el-input v-model="customValue" v-if="customFlag" style="width: 50px;" />
                  </template>
                </el-table-column>
                <el-table-column prop="age" fixed="right" label="操作" width="100" align="center">
                  <template #default="scope">
                    <i class="red icon-jianqu hand" @click="deleteTr(scope.$index, scope.row.product_id)"></i>
                    &nbsp;&nbsp;
                    <i class="blue icon-tianjia hand" @click="addTr(scope.$index)"></i>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="销售日期">
              <el-date-picker
                  v-model="customer_form.sale_date"
                  type="date"
                  placeholder="销售日期"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  style="width:150px;"
              />
              <div class="red">&nbsp;*&nbsp;只能选两个月前的日期</div>
            </el-form-item>
            <el-form-item label="到货日期">
              <el-date-picker
                  v-model="customer_form.delivery_date"
                  type="date"
                  placeholder="到货日期"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  style="width:150px;"
              /><div class="red">&nbsp;&nbsp;可以为空，只能选两个月前的日期</div>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="customer_form.contacts" placeholder="联系人" autocomplete="off" style="width:150px;"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="customer_form.contact_phone" placeholder="联系电话" autocomplete="off" style="width:120px;"></el-input>
            </el-form-item>
            <el-form-item label="销售合同">
              <el-input v-model="customer_form.sale_contract" placeholder="销售合同" autocomplete="off" readonly style="width:300px;"></el-input>
              <el-upload
                  ref="upload"
                  class="upload-demo"
                  :action="upload_action"
                  :data="customer_form"
                  :headers="getHeader()"
                  :show-file-list="false"
                  accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png"
                  :multiple="false"
                  :on-progress="handleProgress"
                  :before-upload="handleBeforeUpload"
                  :on-success="handleAvatarSuccess"
                  :auto-upload="true"
              >
                <template #trigger>
                  <el-button type="primary" style="cursor: pointer; width: 100px; line-height: 32px; height: 32px; font-size: 14px; background-color: rgba(100, 150, 255);">上 传</el-button>
                </template>
              </el-upload>
              <!-- 上传时加载进度条 -->
              <div v-if="uploading" class="progress-bar">
                <div class="progress-bar-inner">
                  <el-progress type="circle" widt="200" stroke-width="6" :percentage="progress" :status="upload_status">
                    <template #default="{ percentage }">
                      <span class="percentage-value">{{ percentage }}%</span>
                      <span class="percentage-label">上传中。。。</span>
                    </template>
                  </el-progress>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="visibleDialog = false">取 消</el-button>
            <el-button type="primary" style="width: 80px;" @click="saveCustomer">保 存</el-button>
          </div>
        </template>
      </el-dialog>
    </template>
    <template v-slot:foot_msg>
      <div style="clear:both; text-align: left; margin-top: 10px;">
        <span style="color:red;padding-left: 20px;line-height: 50px;">所有信息均为必填项，注意：销售日期为合同真是签订日期，不可乱写。</span>
      </div>
    </template>
  </RightSide>
</template>
<script>
import {reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'

export default {
  name: 'Customer',
  components: {
    RightSide
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    //公共数据
    var project_id = Api.sessionGet('project_id');
    const customer_form = ref({});
    const visibleDialog = ref(false);
    let upload_action = ref(Api.BASE_URL+'customer/importSaleContract');
    const uploading = ref(false);
    const progress = ref(0);
    const upload_status = ref('');
    const customFlag = ref(false);
    const customValue = ref('');
    const selected_options = [
      { text: '台', value: '台' },
      { text: '个', value: '个' },
      { text: '辆', value: '辆' },
      { text: '吨', value: '吨' },
      { text: '千克', value: '千克' },
      { text: '双', value: '双' },
      { text: '对', value: '对' },
      { text: '套', value: '套' },
      { text: '张', value: '张' },
      { text: '盒', value: '盒' },
      { text: '米', value: '米' },
      //{ text: '自定义', value: 'custom' },
    ];
    let _this = reactive({
      action: Api.UPLOAD_URL,
      site: Api.WEB_SITE,
      project_id: project_id,
      customer_list: [],
      product_list: [],
      supplier_date: 0,
    });

    ///// 信息上传 Start /////
    const getHeader = ()=>{
      let headers = Api.getHeader();
      let header = {}
      header.USERKEY = headers.userKey;
      header.USERTOKEN = headers.token;
      return header
    }
    // 文件上传前触发
    const handleBeforeUpload = (file) => {
      const flag = ref(true);
      const allowType = 'doc,docx,pdf,jpeg,jpg,png';
      const type = file.name.split(".")[1];
      //const fileType = item.name.substring(item.name.lastIndexOf('.') + 1)
      if (allowType.indexOf(type) == -1) {
        Api.msg('文件格式错误！', 'error', 25)
        flag.value = false;
        return false;
      } else if (file.size / 1024 / 1024 > 2) {
        Api.msg('上传文件大小不能超过 2MB!', 'error', 25);
        flag.value = false;
        return false;
      }
      return flag.value;
    }
    //上传成功
    function handleAvatarSuccess(response){
      if(response.code==0) {
        Api.msg('上传成功', 'success');
        customer_form.value.sale_contract = response.url;
        uploading.value = false;
      }
    }
    const handleProgress = (event) => {
      // 显示上传中
      uploading.value = true;
      // 计算进度
      progress.value = Math.round((event.loaded / event.total) * 100);
      if (progress.value == 100) {
        upload_status.value = 'success';
      }
    };
    ///// 信息上传 End /////
    onMounted(() => {
      getCustomerList();
      getSupplierDate();
    })
    //获取顾客信息
    const getCustomerList = () => {
      proxy.$post('customer/index').then(res => {
        if (res.code == 0) {
          _this.customer_list = res.data.data
        }else {
          Api.msg(res.msg);
        }
      })
    }
    //更新顾客信息
    const saveCustomer = () => {
      proxy.$post('customer/saveCustomer', {
        'customer_form':customer_form.value,
        'product_list':_this.product_list,
      }).then(res => {
        Api.msg(res.msg, 0, '', function () {
          if (res.code == 0) {
            visibleDialog.value = false;
            getCustomerList();
            resetPercent();
          }
        });
      });
    }
    //获取最新一次供货时间
    const getSupplierDate = () => {
      proxy.$post('supplier/getSupplierDate').then(res => {
        if (res.code == 0) {
          _this.supplier_date = res.supplier_date
        }
      })
    }
    // 禁用日期函数
    const disabledDate = (time) => {
      const now = Date.now();
      let thirdMonthsAgo = new Date(now - (3 * 30 * 24 * 60 * 60 * 1000)); // 3个月前
      if (_this.supplier_date != 0) {
        thirdMonthsAgo = new Date(_this.supplier_date * 1000);
      }
      const twoMonthsAgo = new Date(now - (1.5 * 30 * 24 * 60 * 60 * 1000)); // 2个月前
      return thirdMonthsAgo > time.getTime() || time.getTime() > twoMonthsAgo;
    };
    // 添加顾客信息
    const addCustomer = () => {
      customer_form.value = {};
      _this.product_list = [{product_name:'', product_model:'', product_num:0, product_unit:''}];
      visibleDialog.value = true;
    }
    // 编辑顾客信息
    const editCustomer = (row) => {
      customer_form.value = row;
      if (row.product_list.length == 0) {
        _this.product_list = [{product_name:'', product_model:'', product_num:0, product_unit:''}];
      }else {
        _this.product_list = row.product_list;
      }
      visibleDialog.value = true;
    }
    // 获取产品单位
    const handChange = (val) => {
      console.log(val);
      if (val === 'custom'){
        customFlag.value = true;
      }
    }
    // 产品删除
    const deleteTr = (index)=>{
      if (index != 0){
        Api.confirm('是否删除此产品信息?',()=>{
          _this.product_list.splice(index, 1);
        });
      }
    }
    // 产品添加
    const addTr = (index)=>{
      var newTr = {product_name:'', product_model:'', product_num:0, product_unit:''}
      _this.product_list.splice(index+1, 0, newTr)
    }
    // 删除顾客信息
    const handleDelete = (row) => {
      Api.confirm('是否删除此顾客信息?',()=>{
        proxy.$post('customer/delCustomer',{
          customer_id:row.customer_id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getCustomerList();
            resetPercent();
          }
          Api.msg(res.msg);
        });
      });
    }
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -8;
      context.emit("updatePercent", data)
    }
    return {
      ...toRefs(_this),customer_form,visibleDialog,
      upload_action,getHeader,handleBeforeUpload,handleAvatarSuccess,
      getSupplierDate,disabledDate,addCustomer,editCustomer,saveCustomer,handleDelete,resetPercent,
      uploading,progress,handleProgress,upload_status,
      addTr,deleteTr,customFlag,customValue,selected_options,handChange,
    }
  }
}
</script>

<style scoped>
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  z-index: 1000;
}
.progress-bar-inner {
  position: relative;
  top: 30%;
  left: 50%;
  width: 300px;
  height: 200px;
  background-color: #ffffff;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  padding-top: 30px;
  z-index: 1001;
}
.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}
.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
.btn.cancle {
  background: transparent;
  color: #9D9FA6;
  font-size: 14px;
}
.btn {
  background: var(--color-text-blue);
  color: #fff;
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
}
</style>
