import  {createStore }  from 'vuex'

const store = createStore({
  state:{
    companyName: '河北证优科技有限公司'
  },
  mutations:{
    chanegCompName(state,n){
      state.companyName = n
    }
  },
  actions:{},
  getters:{},
  modules:{},
})
export default store