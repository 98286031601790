<template>
  <el-dialog v-model="childDialogVisible" title="信息管理" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleClose">
    <div v-if="show_flag==1">
      <div class="title">最高管理者</div>
      <div class="middle">
        <el-form ref="formRef" :model="staff_form" :rules="rules" label-width="80px">
          <!-- 姓名 -->
          <el-form-item label="姓名" prop="staff_name">
            <el-input v-model="staff_form.staff_name" placeholder="请输入姓名" style="width:150px;"></el-input>
          </el-form-item>
          <!-- 职位 -->
          <el-form-item label="职位" prop="staff_position">
            <el-input v-model="staff_form.staff_position" placeholder="请输入职位" style="width:100px;"></el-input>
            <div class="red">&nbsp;&nbsp;&nbsp;注：职位手动填写，如董事长，总经理等。</div>
          </el-form-item>
          <!-- 学历 -->
          <el-form-item label="学历" prop="staff_education">
            <el-select v-model="staff_form.staff_education" placeholder="请选择" style="width: 165px;">
              <el-option label="高中" value="高中"></el-option>
              <el-option label="专科" value="专科"></el-option>
              <el-option label="本科" value="本科"></el-option>
              <el-option label="研究生" value="研究生"></el-option>
              <el-option label="博士" value="博士"></el-option>
            </el-select>
          </el-form-item>
          <!-- 性别 -->
          <el-form-item label="性别" prop="staff_gender">
            <el-select v-model="staff_form.staff_gender" placeholder="请选择" style="width: 165px;">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </el-form-item>
          <!-- 入职时间 -->
          <el-form-item label="入职时间" prop="entry_time">
            <el-date-picker
                v-model="staff_form.entry_time"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <!-- 总经理电话 -->
          <el-form-item label="联系方式" prop="mobile">
            <el-input v-model="staff_form.mobile" placeholder="请输入联系方式" style="width:150px;" ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveDepartmentManager();" :disabled="is_manager_btn">下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="show_flag==2">
      <div class="title">部门信息</div>
      <div class="middle">
        <el-form label-width="50px">
          <el-form-item>
            <el-table :data="department_list" border show-header>
              <el-table-column label="部门名称" width="150" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.name" placeholder="请输入部门名称"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="部门职能" width="350">
                <template #default="scope">
                  <el-checkbox-group v-model="scope.row.tags_ids">
                    <el-checkbox v-for="(it,k) in tags_list" :key="k" :label="it.tags_id">{{it.tags_name}}</el-checkbox>
                  </el-checkbox-group>
                </template>
              </el-table-column>
              <el-table-column prop="age" fixed="right" label="操作" width="100" align="center">
                <template #default="scope">
                  <i class="red icon-jianqu hand" @click="deleteTr(scope.$index, scope.row.department_id)"></i>
                  &nbsp;&nbsp;
                  <i class="blue icon-tianjia hand" @click="addTr(scope.$index)"></i>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="show_flag=1;">上一步</el-button>
            <el-button type="primary" @click="saveDepartment();" :disabled="is_department_btn">下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="show_flag==3">
      <div class="title">各部门负责人</div>
      <div clall="middle">
        <el-form label-width="10px">
          <el-form-item>
            <el-table :data="department_head_list" border show-header>
              <el-table-column label="部门名称" width="120">
                <template #default="scope">
                  {{scope.row.department_name}}
                </template>
              </el-table-column>
              <el-table-column prop="staff_name" label="负责人">
                <template #default="scope">
                  <el-input v-model="scope.row.staff_name" placeholder="负责人姓名" autocomplete="off" style="width: 130px;"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="staff_position" label="职位" width="150">
                <template #default="scope">
                  <el-input v-model="scope.row.staff_position" placeholder="请输入职位" autocomplete="off" style="width: 120px;"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="staff_education" label="学历" width="120">
                <template #default="scope">
                  <el-select v-model="scope.row.staff_education" placeholder="请选择">
                    <el-option label="博士" value="博士"></el-option>
                    <el-option label="硕士" value="硕士"></el-option>
                    <el-option label="本科" value="本科"></el-option>
                    <el-option label="大专" value="大专"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="staff_gender" label="性别" width="120">
                <template #default="scope">
                  <el-select v-model="scope.row.staff_gender" placeholder="请选择">
                    <el-option label="男" :value="1"></el-option>
                    <el-option label="女" :value="2"></el-option>
                    <el-option label="保密" :value="0"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="entry_time" label="入职时间">
                <template #default="scope">
                  <el-date-picker v-model="scope.row.entry_time" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="选择日期" style="width: 130px;"></el-date-picker>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="show_flag=2;">上一步</el-button>
            <el-button type="primary" @click="saveDepartmentHead();" :disabled="is_department_head_btn">保 存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {getCurrentInstance, onMounted, ref, watch} from "vue";

export default {
  name: "InformationDialog",
  setup(props, ctx) {
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    const childDialogVisible = ref(false);
    const formRef = ref(null);
    const staff_form = ref({});
    const show_flag = ref(0);
    const department_list = ref([]);
    const tags_list = ref([]);
    const department_head_list = ref([]);
    const is_manager_btn = ref(false);
    const is_department_btn = ref(false);
    const is_department_head_btn = ref(false);

    // 表单验证规则
    const rules = {
      staff_name: [
          { required: true, message: '请填写姓名！', trigger: 'blur'},
      ],
      staff_position: [
          { required: true, message: '请填写职位名称！', trigger: 'blur'}
      ],
      staff_education: [{ required: true, message: '请选择学历！', trigger: 'blur'}],
      staff_gender: [{ required: true, message: '请选择性别！', trigger: 'blur'}],
      entry_time: [{ required: true, message: '请选择入职时间！', trigger: 'blur'}],
      mobile: [{ required: true, pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确的手机号码', trigger: 'blur'}],
    }
    // 获取总经理职能的人员信息 getManagerInfo
    const getManagerInfo = () => {
      proxy.$post("/department/getManagerInfo",{}).then((response)=>{
        if (response.code === 0) {
          staff_form.value = response.data;
        }else {
          Api.msg(response.msg, 'error', 50);
        }
      });
    }
    // 保存最高管理者信息
    const saveDepartmentManager = ()=>{
      // 验证表单
      formRef.value.validate((valid) => {
        if (valid) {
          is_manager_btn.value = true;
          let data_arr = [];
          data_arr['staff_id'] = staff_form.value.staff_id;
          data_arr['staff_name'] = staff_form.value.staff_name;
          data_arr['staff_position'] = staff_form.value.staff_position;
          data_arr['entry_time'] = staff_form.value.entry_time;
          data_arr['mobile'] = staff_form.value.mobile;
          data_arr['staff_education'] = staff_form.value.staff_education;
          data_arr['staff_gender'] = staff_form.value.staff_gender;
          data_arr['department_id'] = staff_form.value.department_id;
          proxy.$post('department/saveDepartmentManager',data_arr).then((res)=>{
            if(res.code!=0){
              Api.msg(res.msg, 'error', 50);
              return false;
            }else {
              show_flag.value = 2;
              setTimeout(function () {
                is_manager_btn.value = false;
              },2000);
            }
          });
        }else {
          console.log('test')
          return false;
        }
      });
    }
    // 获取部门列表
    const getDepartmentList = () => {
      proxy.$post("/department/getDepartmentList",{}).then((response)=>{
        if (response.code == 0) {
          department_list.value = response.data.department_list;
          if (response.data.department_list.length == 0) {
            department_list.value = [{name:'', tags_ids:[]}];
          }
          tags_list.value = response.data.tags_list;
        }else {
          Api.msg(response.msg, 'error', 50);
        }
      });
    };
    // 保存部门信息
    const saveDepartment = ()=>{
      is_department_btn.value = true;
      proxy.$post('department/saveDepartmentList',department_list.value).then((res)=>{
        if(res.code==0){
          Api.msg(res.msg,'success', 50,function(){
            is_department_btn.value = false;
            show_flag.value = 3;
          });
        }else {
          Api.msg(res.msg, 'error', 50);
          return false;
        }
      });
    }
    // 获取部门负责人列表
    const getDepartmentHeadList = () => {
      proxy.$post("/department/getDepartmentHeadList",{}).then((response)=>{
        console.log(response.data);
        if (response.code === 0) {
          department_head_list.value = response.data;
        }else {
          Api.msg(response.msg, 'error', 50);
        }
      });
    };
    // 保存部门负责人信息
    const saveDepartmentHead = ()=>{
      is_department_head_btn.value = true;
      proxy.$post('department/saveDepartmentHead',department_head_list.value).then((res)=>{
        if (res.code == 0) {
          Api.msg(res.msg,'success', 50,function() {
            is_department_head_btn.value = false;
            childDialogVisible.value = false;
            //handleClose();
          });
        }
      });
    }
    // 部门删除
    const deleteTr = (index, department_id)=>{
      if (index != 0){
        Api.confirm('是否删除此部门信息?',()=>{
          if (department_id == 0 || department_id == undefined || department_id == null) {
            department_list.value.splice(index, 1);
          }else {
            proxy.$post('department/delDepartment', {
              id: department_id,
            }).then((res) => {
              if (res.code == 0 || res.code == 200) {
                department_list.value.splice(index, 1);
                //Api.msg(res.msg,'success', 50);
              } else {
                Api.msg(res.msg, 'error', 50);
              }
            });
          }
        });
      }
    }
    // 部门添加
    const addTr = (index)=>{
      var newTr = {name:'', tags_ids:[]}
      department_list.value.splice(index+1, 0, newTr)
    }
    // 关闭弹窗
    const handleClose = () => {
      ctx.emit("getDepartList");
    };
    // 初始化
    onMounted(()=>{
    });
    // 初始化数据
    function initData() {
      proxy.$post('department/check_form').then((res)=>{
        if(res.code!=0){
          Api.msg('网络错误', 'error', 50);
          return false;
        }else {
          show_flag.value = res.data;
          /*if(res.data==1){
            getManagerInfo();
          }*/
        }
      });
    }
    // 监听 show_flag
    watch(show_flag, (val)=>{
      if (val == 1) {
        getManagerInfo();
      }
      if (val == 2) {
        getDepartmentList();
      }
      if (val == 3) {
        getDepartmentHeadList();
      }
    });
    return {
      show_flag,childDialogVisible,initData,staff_form,formRef,rules,department_list,tags_list,department_head_list,
      is_manager_btn,is_department_btn,is_department_head_btn,
      deleteTr,addTr,saveDepartmentManager,getManagerInfo,handleClose,
      saveDepartment,getDepartmentList,getDepartmentHeadList,saveDepartmentHead,
    }
  }
}
</script>

<style scoped>
.title{
  font-size: 20px;
  font-weight: bold;
  line-height: 3em;
  margin: 0 1.25vw;
  border-bottom: 1px solid #DADCE5;
  margin-bottom: 1.25vw;
}
.middle{
  padding: 0 2.08vw;
}
</style>
