<!-- 执行标准 -->
<template>
  <RightSide>
    <template v-slot:tit>执行标准</template>
 <!--    <template v-slot:tit_right>
        <div class="flex_1 flex--c">
          进度 <el-progress class="ml-15" :percentage="10" :stroke-width="16" define-back-color="#F5F6FA"></el-progress>
        </div>
      </template> -->
    <template v-slot:write_msg>
      <el-form ref="formScreening" label-width="80px" @submit.native.prevent>
        <div class="flex">
          <div class="input">
            <el-select v-model="type" placeholder="名称" style="width:100px">
              <el-option v-for="item in dropdownList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <input v-model="keyword" :placeholder="type==1?'请输入标准名称或编号':'请输入条款、法规编号'" @keyup.enter.native="search"/><i class="icon-icon-" @click="search"></i>
          </div>
          <button type="button" style="float: right;" class="chooseBtn" @click="getMyListing(true)">已选标准({{pickedList.length}})</button>
        </div>
      </el-form>
      <template v-if="search_list_flag">
      <div class="prudence f14">为您搜索到 <span class="red">{{count}}条</span> 相关信息</div>
      <ul v-if="list.length>0">
        <li v-for="(item, index) in list" :key="index" :class="item.is_add?'picked flex':'flex'">
          <div class="tit line1">{{item.name}}</div>
          <div class="num line1">{{item.number}}</div>
          <div class="addbtn" @click="checkThis(item,index)"><i class="icon-tianjia"></i></div>
        </li>
      </ul>
      <el-empty v-else description="暂无清单！"></el-empty>
      <div class="flex-b-c mb-10">
        <!--<span @click="chooesedList">所选清单</span>-->
        <!-- 分页 -->
        <el-pagination v-if="list.length>0"  @current-change="handleCurrentChange" v-model:current-page="currentPage"
          :page-size="pageSize" layout="prev, pager, next, jumper" :total="count">
        </el-pagination>
        <!-- <el-pagination layout="prev, pager, next" :page-size="8" :total="list.length" ></el-pagination> -->
      </div>
      </template>
      <template v-if="my_list_flag">
        <div class="prudence f14">已选 <span class="red">{{pickedList.length}}条</span> 信息</div>
          <ol  v-if="pickedList.length>0" style="min-height: 100px;">
            <li v-for="(item, index) in pickedList" :key="index" class="flex" :class="item.is_add==1?'picked':''">
              <div class="tit line1">{{item.name}}</div>
              <div class="num">{{item.number}}</div>
              <div class="addbtn" v-if="item.fiexd!=1" @click="subtraction(index)"><i class="icon-jianqu"></i></div>
            </li>
          </ol>
          <el-empty v-else description="暂无清单！"></el-empty>
          <button type="button" class="blue hand" @click="deleAll">全部删除</button>
      </template>
    </template>
    <template v-slot:foot_msg>
      <span style="display:flex;align-items: center;height: 4.1vw;color:red;">需要选择您企业所生产的产品的相关执行标准，输入产品名称或者选择标准编号即可。</span>
      <!-- <button style="float: right;" @click="chooesedList">已选清单({{pickedList.length}})</button> -->
    </template>
    <template v-slot:the_popup>
      <!-- 所选清单 弹出窗 -->
      <Popup :isAdd="isShow" @changeEvent="changeIsShow">
        <template v-slot:title>所选清单</template>
        <template v-slot:middle>
          <ol  v-if="pickedList.length>0" style="min-height: 100px;">
            <li v-for="(item, index) in pickedList" :key="index" class="flex" :class="item.is_add==1?'picked':''">
              <div class="tit line1">{{item.name}}</div>
              <div class="num">{{item.number}}</div>
              <div class="addbtn" v-if="item.fiexd!=1" @click="subtraction(index)"><i class="icon-jianqu"></i></div>
            </li>
          </ol>
          <el-empty v-else description="暂无清单！"></el-empty>
        </template>
        <template v-slot:footer>
            <button type="button" class="blue hand" @click="deleAll">全部删除</button>
            <div>
              <span class="btn cancle" @click="cancel">取消</span>
              <span class="btn" @click="create">保存</span>
            </div>
          </template>
      </Popup>
    </template>
  </RightSide>
</template>

<script>
import { ref,reactive,toRefs, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import Popup from '@/components/common/Popup.vue'
import { layer } from "@layui/layer-vue";
export default {
  name: 'ExternalList',
  components: {
    RightSide,
    Popup,
  },
  props: {},
  setup (props,context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    let _this = reactive({
      list:[],
      keyword:'',
      pageSize:10,
      count:0,
      current_page:1,
      pickedList:[],
      cid:0,
      level:0,
      type:1,
      dropdownList:[
        {value: 1, label: '名称'},
        {value: 2, label: '编号'}
      ],
      showClassify:true,
    })
    let my_list_flag = ref(false);
    let search_list_flag = ref(false);
    let currentPage = ref(1)
    let isShow = ref(false)

    onMounted(()=>{
      getMyListing(true);
    })
    //获取外来清单列表
    const getListing = ()=>{
      if(_this.level<1&&_this.cid>0){
        Api.msg('分类至少选择两级！');
        return false;
      }
      var load = layer.load(0,{offset:['40%','65%']});
      proxy.$post("listing/index",{
        page:_this.current_page,
        cid:_this.cid,
        name:_this.keyword,
        type:_this.type,
      }).then((res)=>{
        if (res.code==1) {
          Api.msg(res.msg);
        }else {
          let list = res.data
          _this.list = updateList(list);
          _this.pageSize = res.pageSize;
          _this.count = res.count;
          _this.current_page = res.current_page
        }
        layer.close(load);
      });
    }
    //更新列表
    const updateList = (list)=>{
      let pickIds = Api.array_column(_this.pickedList,'id')
      list.forEach((v,k)=>{
        if(pickIds.indexOf(v.id.toString())>=0){
          list[k].is_add = 1;
        }else{
          list[k].is_add = 0;
        }
      })
      // _this.list = list
      return list;
    }
    //已加入列表中的清单
    const getMyListing = (show=true)=>{
      proxy.$post('listing/init_listing',{}).then((response)=>{
        _this.pickedList = response.list;
        if (_this.pickedList.length>0) {
          search_list_flag.value = false;
          my_list_flag.value = true;
        }else {
          my_list_flag.value = false;
          search_list_flag.value = true;
          if(show)getListing();
        }

      });
    }
    //分类回调
    const changeListing = (cids,level)=>{
      _this.cid = cids[level]||0;
      _this.level = level;
      // console.log(cids,'|',level)
    }
    //筛选显示、隐藏
    const changeClassify = ()=>{
      _this.showClassify = !_this.showClassify
    }
    //切换页码
    const handleCurrentChange = (page)=>{
      _this.current_page = page;
      getListing();
    }

    //搜索
    const search = ()=>{
      _this.current_page = 1;
      my_list_flag.value = false;
      search_list_flag.value = true;
      getListing();
    }
    //添加清单
    const checkThis = (row)=>{
      let isHave = false;
      _this.pickedList.forEach( function(v) {
        if(v.name==row.name){
          isHave = true
        }
      });
      if(isHave){
        layer.msg('此项已添加！');
        return false;
      }
      _this.pickedList.push(row);
      _this.list = updateList(_this.list);
      saveListing();
    }
    //清单的显示隐藏
    const chooesedList = ()=>{
      isShow.value = !isShow.value
    }
    //弹窗的显示隐藏
    const changeIsShow = (n)=>{
      isShow.value = n
    }
    //保存外来清单
    const saveListing = ()=>{
      proxy.$post("listing/saveListing",{
        list:_this.pickedList,
      }).then((res)=>{
        if(res.code==0){
          resetPercent()
          proxy.$message({type: 'success',message: '操作成功！'});
        }else{
          proxy.$message.error('操作失败！');
        }
      })
    }
    //删除清单
    const subtraction = (index)=>{
      proxy.$confirm('你确定要删除清单吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if(_this.pickedList.length==1){
            _this.pickedList = []
          }else{
            _this.pickedList.splice(index, 1)
          }
          _this.list = updateList(_this.pickedList);
          saveListing()
          // proxy.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
      return false;
    }
    //删除清单(全部)
    const deleAll = ()=>{
      proxy.$confirm('你确定要删除全部清单吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let list = _this.pickedList;
          let newList = [];
          list.forEach(v=>{
            if(v.fiexd==1)newList.push(v);
          });
          _this.pickedList = newList;
          _this.list = updateList(_this.list);
          saveListing()
          proxy.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      return false;
    }
    function cancel(){
      isShow.value = false
    }
    //保存外来清单
    const create = ()=>{
      saveListing();
      cancel()
    }
    function handleCommand(){
      console.log(1)
    }
     //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -1;
      context.emit("updatePercent", data)
    }

    return {
      ...toRefs(_this), currentPage, isShow,  getMyListing,saveListing,resetPercent,
      changeClassify,search,changeListing,handleCurrentChange, checkThis, chooesedList, changeIsShow,
      subtraction, cancel, create, deleAll, handleCommand,my_list_flag,search_list_flag
    }
  }
}
</script>

<style scoped>
  .fill{display: inline-block;}
/* 中间部分 */
  form{
    margin-top:24px;
    margin-bottom:24px;
  }
  form>.flex+div{
    margin-top:16px;
  }
  .input{
    background:#F5F6FA;
    border-radius:4px;
    height:36px;
    line-height:36px;
  }
  .input+div{
    margin-left:12px;
    background: rgba(48, 97, 242, .1);
    color: var(--color-text-blue);
    font-size: 16px;
    width: 5em;
    cursor: pointer;
  }
  .input+div i{
    display: inline-block;
    transition: transform .3s;
  }
  .trans180{
    transform: rotate(180deg);
    transition: transform .3s;
  }
  .input .el-select{
    width:100px;
    height:24px;
    line-height:24px;
    border-right:1px solid #9D9FA6;
    margin-top:-5px;
    border-left-color:transparent !important;
    border-top-color:transparent !important;
    border-bottom-color:transparent !important;
    --el-select-border-color-hover: transparent;
  }
  .input :deep(.el-input){
    line-height:24px;
    height:24px;
    border-color: transparent;
  }
  .input :deep(.el-input__wrapper){
    background:transparent;
    box-shadow:none;
  }
  .input :deep(.el-select .el-input.is-focus .el-input__wrapper),
  .input :deep(.el-select .el-input__wrapper.is-focus){
    box-shadow:none !important;
  }
  .input input{
    height:36px;
    line-height:36px;
    border:none;
    background:transparent;
    padding-left:1.19vw;
  }
  .input i{
    width:50px;
    height:36px;
    font-size:22px;
    background: var(--color-text-blue);
    color:#fff;
    text-align: center;
    display: inline-block;
    border-radius:0 4px 4px 0;
    cursor:pointer;
  }
  button{
    width:64px;
    height:32px;
    line-height:32px;
    text-align: center;
    border-radius:4px;
    font-size:16px;
    border:0;
    margin-left:8px;
    cursor:pointer;
  }
  button[type='submit']{
    background: var(--color-text-blue);
    color:#fff;
  }
  button[type='reset']{
    background:#F5F6FA;
    color:#161823;
  }
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height:36px;
    line-height:36px;
    padding:0 15px;
    margin:15px 0;
  }
  ul{
    height:420px;
    overflow: hidden;
    margin:15px 0;
  }
  li{
    background: #F5F6FA;
    height: 40px;
    line-height:40px;
    width:100%;
    border-radius:4px;
    margin:2px 0;
    padding:0 6px 0 18px;
    box-sizing:border-box;
  }
  .tit{
    width:65%;
    font-size:16px;
    font-family: 'PingFang-SC-Medium';
  }
  .num{
    width: calc(35% - 48px);
    font-size:14px;
    font-family: 'PingFang-SC-Medium';
  }
  .addbtn{
    background: var(--color-text-blue);
    color: #fff;
    width: 48px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius:4px;
    margin-top:6px;
    margin-left:5px;
  }
  .picked .addbtn{
    background: rgba(48, 97, 242, .2);
  }
  .flex-b-c>span{
    width:100px;
    height:28px;
    line-height:28px;
    background: var(--color-text-blue);
    color:#fff;
    font-size: 18px;
    font-family: 'PingFang-SC-Bold';
    text-align: center;
    border-radius:4px;
    cursor: default;
  }

/* 下 */
.foot_msg button{
    height:40px;
    line-height:40px;
    font-size:16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius:4px;
    border:none;
    width:200px;
    margin: calc((4.1vw - 40px) / 2) 0;
  }
  /* 弹出框 */
  ol{
    margin:15px 0;
  }

  .cont li.picked .addbtn{
    background: var(--color-text-blue);
  }
  .blue.hand{
    border:0;
    background:none;
  }
  .btn.cancle{
    background:transparent;
    color:#9D9FA6;
    font-size:16px;
  }
  .btn{
    background:var(--color-text-blue);
    color:#fff;
    width:100px;
    height:36px;
    line-height:36px;
    text-align: center;
    border-radius:4px;
    display: inline-block;
  }
  .chooseBtn{
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius: 4px;
    border: none;
    width: 160px;
    /* margin: calc((4.1vw - 40px) / 2) 0; */
  }
</style>
