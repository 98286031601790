<!-- 每页的顶部 -->
<template>
  <div class="head">
    <div class="w1200 flex-b-c">
      <slot name="title"></slot>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top',
  setup(){

  },
}

</script>

<style scoped>
  .head{
    height:60px;
    line-height:60px;
    background:#fff;
    box-shadow:0 1px 1px 0 rgba(22,24,35,.1);
  }
</style>