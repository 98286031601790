<!-- 供方管理 -->
<template>
  <RightSide>
    <template v-slot:tit>供方管理</template>
    <template v-slot:write_msg>
      <div class="flex_1 flex--c mt-10 mb-10">
        <span class="btn" style="cursor: pointer;" @click="addSupplier">添加供方信息</span>
      </div>
      <el-table :data="supplier_list" stripe style="width: 100%">
        <el-table-column prop="supplier_name" label="供方名称" width="220"> </el-table-column>
        <el-table-column prop="product_list" label="产品信息" width="120">
          <template #default="scope">
            <el-popover
                placement="top"
                title="产品信息"
                width="350"
                trigger="click"
            >
              <template #reference>
                <div style="color: #0a84ff; cursor: pointer;">查看详情</div>
              </template>
              <el-table :data="scope.row.product_list">
                <el-table-column width="150" property="product_name" label="产品名称" />
                <el-table-column width="100" property="product_model" label="规格型号" />
                <el-table-column width="100" property="product_num" label="数 量">
                  <template #default="scope">
                    <span>{{ scope.row.product_num }}{{ scope.row.product_unit }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="supplier_date" label="供货日期" width="120"></el-table-column>
        <el-table-column prop="supplier_manager" label="负责人" width="80"></el-table-column>
        <el-table-column label="操作" key="slot" align="left" width="250">
          <template #default="scope">
            <el-button @click="openDialog(scope.row)" style="font-size:14px; width: 100px;">上传检测报告</el-button>
            <el-button @click="editSupplier(scope.row)" style="margin-left: 5px; font-size:14px; width: 50px;">编 辑</el-button>
            <el-button @click="handleDelete(scope.row)" style="color: red;margin-left: 5px; font-size:14px; width: 50px;">删 除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--管理对话框-->
      <el-dialog v-model="visibleDialog" title="供方信息" @close="resetPercent();" style="width: 800px;" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="false">
        <template #default>
          <el-form label-width="80px" :model="supplier_form">
            <el-form-item label="供方名称">
              <el-input v-model="supplier_form.supplier_name" placeholder="请输入供方名称" autocomplete="off" style="width:300px;"></el-input>
              <div class="red">&nbsp;*</div>
            </el-form-item>
            <el-form-item label="主营产品">
              <el-input v-model="supplier_form.main_product" placeholder="请输入供方主营产品" autocomplete="off" style="width:350px;"></el-input>
              <div class="red">&nbsp;*</div>
            </el-form-item>
            <el-form-item label="产品信息"><div class="red">&nbsp;*</div>
              <el-table :data="product_list" border show-header>
                <el-table-column label="产品名称" width="230" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.product_name" placeholder="请输入产品名称"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="规格型号" width="150">
                  <template #default="scope">
                    <el-input v-model="scope.row.product_model" placeholder="请输入型号参数"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="content" label="数量" width="100" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.product_num" placeholder="数量"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="product_unit" label="单位" width="100" align="center">
                  <template #default="scope">
                    <el-select v-model="scope.row.product_unit">
                      <el-option
                          v-for="item in selected_options"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="age" fixed="right" label="操作" width="100" align="center">
                  <template #default="scope">
                    <i class="red icon-jianqu hand" @click="deleteTr(scope.$index, scope.row.product_id)"></i>
                    &nbsp;&nbsp;
                    <i class="blue icon-tianjia hand" @click="addTr(scope.$index)"></i>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="供货日期">
              <el-date-picker
                  v-model="supplier_form.supplier_date"
                  type="date"
                  placeholder="供货日期"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  style="width:150px;"
              /><div class="red">&nbsp;*&nbsp;只能选两个月前的日期</div>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input v-model="supplier_form.supplier_manager" placeholder="负责人" autocomplete="off" style="width:150px;"></el-input>
            </el-form-item>
            <el-form-item label="公司地址">
              <el-input v-model="supplier_form.supplier_address" placeholder="公司地址" autocomplete="off" style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item label="营业执照">
              <el-input v-model="supplier_form.supplier_license" placeholder="营业执照" autocomplete="off" readonly style="width:300px;"></el-input>
              <el-upload
                  ref="upload1"
                  class="upload-demo"
                  :action="upload_action"
                  :data="{supplier_license:supplier_form.supplier_license, upload_type:'supplier_license'}"
                  :headers="getHeader()"
                  :show-file-list="false"
                  accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png"
                  :multiple="false"
                  :on-progress="handleProgress"
                  :before-upload="handleBeforeUpload"
                  :on-success="handleAvatarSuccessFirst"
                  :auto-upload="true"
              >
                <template #trigger>
                  <el-button type="primary" style="cursor: pointer; width: 100px; line-height: 32px; height: 32px; font-size: 14px; background-color: rgba(100, 150, 255);">上 传</el-button>
                </template>
              </el-upload>
              <!-- 上传时加载进度条 -->
              <div v-if="uploading" class="progress-bar">
                <div class="progress-bar-inner">
                  <el-progress type="circle" widt="200" stroke-width="6" :percentage="progress" :status="upload_status">
                    <template #default="{ percentage }">
                      <span class="percentage-value">{{ percentage }}%</span>
                      <span class="percentage-label">上传中。。。</span>
                    </template>
                  </el-progress>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="visibleDialog = false">取 消</el-button>
            <el-button type="primary" style="width: 80px;" @click="saveSupplier">保 存</el-button>
          </div>
        </template>
      </el-dialog>
      <!-- 上传检测报告 -->
      <el-dialog v-model="reportDialog" title="检测报告" style="width: 700px;" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="false">
        <template #default>
          <el-upload
              ref="upload1"
              class="upload-demo"
              :action="upload_action"
              :data="{supplier_id:supplier_id,upload_type:'product_report'}"
              :headers="getHeader()"
              :show-file-list="true"
              accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png"
              :multiple="false"
              :on-progress="handleProgress"
              :before-upload="handleBeforeUpload"
              :on-success="handleAvatarSuccessSecond"
              :on-remove="handleRemove"
              :auto-upload="true"
          >
            <template #trigger>
              <el-button type="primary" style="cursor: pointer; width: 100px; line-height: 32px; height: 32px; font-size: 14px; background-color: rgba(100, 150, 255);">上传报告</el-button>
            </template>
          </el-upload>
          <el-table :data="report_list" border style="width: 430px;">
            <el-table-column
                prop="name"
                label="文件名"
                width="350"
                :show-overflow-tooltip="true"
            >
              <template #default="scope">
                <span v-if="scope.row.type=='文件夹'">
                   <img src="@/assets/images/folder.png" style="width: 25px; height: 25px;">
                   {{ scope.row.name }}
                 </span>
                <span v-if="scope.row.type=='文件'">
                  <img :src="require('@/assets/images/'+  matchType(scope.row.name)  +'.png')" style="width: 25px; height: 25px; vertical-align: middle;" />
                  {{ scope.row.name }}
                 </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" key="slot" align="left" width="80">
              <template #default="scope">
                <img src="@/assets/images/del.png" style="width: 20px; height: 20px; cursor: pointer;" @click="handleDeleteReport(scope.row.name)" />
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="reportDialog = false">关 闭</el-button>
          </div>
        </template>
      </el-dialog>
    </template>
    <template v-slot:foot_msg>
      <div style="clear:both; text-align: left; margin-top: 10px;">
        <span style="color:red;padding-left: 20px;line-height: 30px;">所有内容均为必填项，供方产品的规格、名称按实际情况填写，一个供方可提供多项产品。如需上传产品检测报告，待供方信息填写完毕后，于列表右侧进行上传。</span>
      </div>
    </template>
  </RightSide>
</template>
<script>
import {reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import {matchType} from "@/store/matchType";

export default {
  name: 'Supplier',
  methods: {matchType},
  components: {
    RightSide
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    //公共数据
    var project_id = Api.sessionGet('project_id');
    const supplier_form = ref({});
    const visibleDialog = ref(false);
    let upload_action = ref(Api.BASE_URL+'/supplier/importSupplierContract');
    const uploading = ref(false);
    const progress = ref(0);
    const upload_status = ref('');
    const reportDialog = ref(false);
    const supplier_id = ref(0);
    const selected_options = [
      { text: '台', value: '台' },
      { text: '个', value: '个' },
      { text: '辆', value: '辆' },
      { text: '吨', value: '吨' },
      { text: '千克', value: '千克' },
      { text: '双', value: '双' },
      { text: '对', value: '对' },
      { text: '套', value: '套' },
      { text: '张', value: '张' },
      { text: '盒', value: '盒' },
      { text: '米', value: '米' },
      //{ text: '自定义', value: 'custom' },
    ];
    let _this = reactive({
      action: Api.UPLOAD_URL,
      site: Api.WEB_SITE,
      project_id: project_id,
      supplier_list: [],
      product_list: [],
      report_list: [],
    });

    ///// 信息上传 Start /////
    const getHeader = ()=>{
      let headers = Api.getHeader();
      let header = {}
      header.USERKEY = headers.userKey;
      header.USERTOKEN = headers.token;
      return header
    }
    // 文件上传前触发
    const handleBeforeUpload = (file) => {
      const flag = ref(true);
      const allowType = 'doc,docx,pdf,jpeg,jpg,png';
      const type = file.name.split(".")[1];
      //const fileType = item.name.substring(item.name.lastIndexOf('.') + 1)
      if (allowType.indexOf(type) == -1) {
        Api.msg('文件格式错误！', 'error', 25)
        flag.value = false;
        return false;
      } else if (file.size / 1024 / 1024 > 2) {
        Api.msg('上传文件大小不能超过 2MB!', 'error', 25);
        flag.value = false;
        return false;
      }
      return flag.value;
    }
    //上传成功
    function handleAvatarSuccessFirst(response){
      if(response.code==0) {
        Api.msg('上传成功', 'success');
        supplier_form.value.supplier_license = response.url;
        uploading.value = false;
      }
    }
    // 进度条
    const handleProgress = (event) => {
      // 显示上传中
      uploading.value = true;
      // 计算进度
      progress.value = Math.round((event.loaded / event.total) * 100);
      if (progress.value == 100) {
        upload_status.value = 'success';
      }
    };
    ///// 信息上传 End /////
    onMounted(() => {
      getSupplierList()
    })
    //获取供方信息
    const getSupplierList = () => {
      proxy.$post('supplier/index').then(res => {
        if (res.code == 0) {
          /*var product_str = '';
          for (const resKey in res.data) {
            console.log(res.data[resKey].product_list);
            if (res.data[resKey].product_list.length>0) {
              for (const productKey in res.data[resKey].product_list) {
                product_str += res.data[resKey].product_list[productKey].product_name + '、';
              }
              res.data[resKey].product_str = product_str;
            }
          }*/
          _this.supplier_list = res.data
        }else {
          Api.msg(res.msg);
        }
      })
    }
    //更新供方信息
    const saveSupplier = () => {
      proxy.$post('supplier/saveSupplier', {
        'supplier_form':supplier_form.value,
        'product_list':_this.product_list,
      }).then(res => {
        Api.msg(res.msg, 0, '', function () {
          if (res.code == 0) {
            visibleDialog.value = false;
            getSupplierList();
            resetPercent();
          }
        });
      });
    }
    // 添加供方信息
    const addSupplier = () => {
      supplier_form.value = {};
      _this.product_list = [{product_name:'', product_model:'', product_num:0, product_unit:''}];
      visibleDialog.value = true;
    }
    // 编辑供方信息
    const editSupplier = (row) => {
      supplier_form.value = row;
      if (row.product_list.length == 0) {
        _this.product_list = [{product_name:'', product_model:'', product_num:0, product_unit:''}];
      }else {
        _this.product_list = row.product_list;
      }
      visibleDialog.value = true;
    }
    // 删除供方信息
    const handleDelete = (row) => {
      Api.confirm('是否删除此供方信息?',()=>{
        proxy.$post('supplier/delSupplier',{
          supplier_id:row.supplier_id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getSupplierList();
            resetPercent();
          }
          Api.msg(res.msg);
        });
      });
    }
    // 产品删除
    const deleteTr = (index)=>{
      if (index != 0){
        Api.confirm('是否删除此产品信息?',()=>{
          _this.product_list.splice(index, 1);
        });
      }
    }
    // 产品添加
    const addTr = (index)=>{
      var newTr = {product_name:'', product_model:'', product_num:0, product_unit:''}
      _this.product_list.splice(index+1, 0, newTr)
    }
    /********* 检测报告 ***********/
    const openDialog = (row) => {
      supplier_id.value = row.supplier_id;
      getProductReport();
      reportDialog.value = true;
    }
    function handleAvatarSuccessSecond(response){
      if(response.code==0) {
        Api.msg('上传成功', 'success');
        /*var arr = {name:response.url,type:'文件'};
        _this.report_list.push(arr);*/
        getProductReport();
        uploading.value = false;
      }
    }
    function handleRemove(file){
      proxy.$post('supplier/delProductReport', {
        'supplier_id' : supplier_id.value,
        'product_report' : file.response.url,
      }).then(res => {
        if (res.code == 0) {
          getProductReport();
        }
      });
    }
    //获取产品检测报告
    const getProductReport = () => {
      proxy.$post('supplier/getProductReport', {
        'supplier_id' : supplier_id.value,
      }).then(res => {
        if (res.code == 0) {
          _this.report_list = res.data;
        }
      });
    }
    // 删除产品检测报告
    const handleDeleteReport = (row) => {
      proxy.$post('supplier/delProductReport', {
        'supplier_id' : supplier_id.value,
        'product_report' : row,
      }).then(res => {
        if (res.code == 0) {
          getProductReport();
        }
      });
    }
    // 禁用日期函数
    const disabledDate = (time) => {
      const now = Date.now();
      const thirdMonthsAgo = new Date(now - (3 * 30 * 24 * 60 * 60 * 1000)); // 3个月前
      const twoMonthsAgo = new Date(now - (2 * 30 * 24 * 60 * 60 * 1000)); // 2个月前
      return thirdMonthsAgo > time.getTime() || time.getTime() > twoMonthsAgo;
    };
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -8;
      context.emit("updatePercent", data)
    }
    return {
      ...toRefs(_this),supplier_form,visibleDialog,reportDialog,selected_options,
      upload_action,uploading,progress,upload_status,supplier_id,disabledDate,
      getHeader,handleBeforeUpload,handleAvatarSuccessFirst,handleProgress,
      addSupplier,editSupplier,saveSupplier,handleDelete,resetPercent,
      addTr,deleteTr,openDialog,handleAvatarSuccessSecond,handleRemove,handleDeleteReport,
    }
  }
}
</script>

<style scoped>
/* 覆盖操作按钮的样式 */
.el-upload-list__item .el-icon--upload-success{
  font-size: 20px;
}
.el-upload-list__item .el-icon--close{
  font-size: 20px;
  color: #f00;
}
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  z-index: 1000;
}
.progress-bar-inner {
  position: relative;
  top: 30%;
  left: 50%;
  width: 300px;
  height: 200px;
  background-color: #ffffff;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  padding-top: 30px;
  z-index: 1001;
}
.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}
.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
.btn.cancle {
  background: transparent;
  color: #9D9FA6;
  font-size: 14px;
}
.btn {
  background: var(--color-text-blue);
  color: #fff;
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
}
</style>

