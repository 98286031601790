<!-- 部门信息 -->
<template>
  <RightSide>
    <template v-slot:tit>部门人员</template>
    <template v-slot:write_msg>
      <div class="flex_1 flex--c mt-10 mb-10">
        <span class="btn" style="cursor: pointer;" @click="openDialog">添加信息</span>&nbsp;&nbsp;
        <span style="color:red; padding-left: 20px;">*请先完善各部门信息，然后再填写各部门负责人和员工信息</span>
      </div>
      <el-table :data="department_list" stripe style="width: 100%">
        <el-table-column prop="name" label="部 门" width="100">
          <template #default="scope">
            {{ scope.row.name }}
            <el-icon size="16" color="#409efc" style="vertical-align: middle; cursor: pointer;" v-if="scope.row.is_manager==0" @click="editDepartmentNameDialog(scope.row)">
              <Edit />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column prop="staff_name" label="负责人" width="100"></el-table-column>
        <el-table-column prop="staff_position" :formatter="formatter" label="职位"></el-table-column>
        <el-table-column prop="staff_education" label="学历" width="80"></el-table-column>
        <el-table-column prop="staff_gender" label="性别" width="80"></el-table-column>
        <el-table-column prop="entry_time" label="入职时间"></el-table-column>
        <el-table-column label="操作" key="slot" align="left" width="220">
          <template #default="scope">
            <el-button v-if="scope.row.is_manager==0" @click="departmentDialog(scope.row)" style="margin-left:3px; font-size:14px;">下级部门</el-button>
            <el-button @click="openStaffDialog(scope.row)" style="margin-left:3px; font-size:14px;">管理员工</el-button>
<!--            <el-button v-if="scope.row.is_manager==1" @click="handleSEdit(scope.row)" style="margin-left: 4px; font-size:14px;">编辑人员</el-button>-->
<!--            <el-button v-if="scope.row.is_manager==0" @click="editDepartmentDialog(scope.row)" style="margin-left: 3px; font-size:14px; width: 50px;">编 辑</el-button>-->
            <el-button v-if="scope.row.is_manager==0" @click="handleDelete(scope.row)" style="color: red;margin-left: 3px; font-size:14px; width: 50px;">删 除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--修改部门名称-->
      <el-dialog v-model="editDepartmentNameDialogVisible" title="部门信息" :close-on-click-modal="false" :close-on-press-escape="false" @close="resetPercent();" width="550">
        <template #default>
          <el-form label-width="80px">
            <!-- 部门名称 -->
            <el-form-item label="部门名称">
              <el-input v-model="oneDepartment.name" placeholder="请输入部门名称" style="width:300px;"></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="editDepartmentNameDialogVisible = false">取 消</el-button>
            <el-button type="primary" style="width: 80px;" @click="saveDepartmentName" :disabled="is_department_name_btn">保 存</el-button>
          </div>
        </template>
      </el-dialog>
      <!--管理下级部门对话框-->
      <el-dialog v-model="departmentDialogVisible" title="下级部门信息" :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="flex_1 flex--c mb-10">
          <span class="btn" style="cursor: pointer;" @click="addDepartmentDialog">添加下级部门</span>
        </div>
        <el-table :data="department_child_list" stripe style="width: 100%">
          <el-table-column prop="name" label="部 门" width="150"></el-table-column>
          <el-table-column prop="tags_names" label="职 能"></el-table-column>
          <el-table-column prop="parent_name" label="所属部门" width="150"></el-table-column>
          <el-table-column label="操 作" align="center">
            <template #default="scope">
              <el-button @click="openStaffDialog(scope.row)" style="margin-left:3px; font-size:14px;">管理员工</el-button>
              <el-button @click="editDepartmentDialog(scope.row)" style="margin-left:3px; font-size:14px;">编 辑</el-button>
              <el-button @click="deleteDepartmentChild(scope.row)" style="color: red;margin-left: 3px; font-size:14px;">删 除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <!--添加/修改下级部门对话框-->
      <el-dialog v-model="departmentChildDialogVisible" title="下级部门信息" width="550" :close-on-click-modal="false" :close-on-press-escape="false">
        <template #default>
          <el-form label-width="80px">
            <!-- 部门名称 -->
            <el-form-item label="部门名称">
              <el-input v-model="row.name" placeholder="请输入部门名称" style="width:300px;"></el-input>
            </el-form-item>
            <!-- 所属部门 -->
            <el-form-item label="所属部门">
              {{parent_name}}
            </el-form-item>
            <!-- 复选 -->
            <el-form-item :label="'部门职能'">
              <el-checkbox-group v-model="row.tags_ids">
                <el-checkbox v-for="(it,k) in tagsList" :key="k" :label="it.tags_id">{{it.tags_name}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="departmentChildDialogVisible = false">取消</el-button>
            <el-button type="primary" style="width: 100px;" @click="saveDepartment">保存</el-button>
          </div>
        </template>
      </el-dialog>
      <!--管理员工对话框-->
      <StaffDialog ref="staffDialogVisible" @getDepartList="getDepartList" @close="resetPercent();"></StaffDialog>
      <!--信息添加对话框-->
      <InformationDialog ref="dialogVisible" @getDepartList="getDepartList" @close="resetPercent();"></InformationDialog>
    </template>
    <template v-slot:foot_msg>
       <span  style="color:red;padding-left: 20px;line-height: 50px;"></span>
    </template>
  </RightSide>
</template>

<script>
import { ref, reactive,toRefs, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import StaffDialog from './staffDialog.vue'
import InformationDialog from '@/components/InformationDialog.vue'

export default {
  name: 'Department',
  components: {
    RightSide,
    StaffDialog,
    InformationDialog,
  },
  props: {},
  setup (props,context) {
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    const departmentDialogVisible = ref(false);
    const departmentChildDialogVisible = ref(false);
    const editDepartmentNameDialogVisible = ref(false);
    const is_department_name_btn = ref(false);
    const dialogVisible = ref(null);
    const parent_id = ref(0);
    const parent_name = ref('');

    onMounted(()=>{
      getDepartList();
    });
    let _this = reactive({
      row:{},
      oneDepartment:{},
      department_list:[],
      tagsList:[],
      department_child_list:[],
    });
    ///// Start ///////////////////////////////////////////////
    // 获取部门列表
    const getDepartList = ()=> {
      proxy.$post("/department/list",{}).then((response)=>{
        _this.department_list = response.departList;
        _this.tagsList = response.tagsList;
      })
    }
    // 添加部门
    const openDialog = () =>{
      dialogVisible.value.childDialogVisible = true;
      dialogVisible.value.initData();
    }
    // 修改部门名称
    const editDepartmentNameDialog = (row) => {
      _this.oneDepartment = row;
      editDepartmentNameDialogVisible.value = true;
    }
    // 保存部门名称
    const saveDepartmentName = () =>{
      is_department_name_btn.value = true;
      let row = _this.oneDepartment;
      if(!row.name){
        Api.msg('请填写部门名称！','error', 50);
        return false;
      }
      proxy.$post('department/saveDepartmentName',row).then((res)=>{
        if(res.code==0){
          getDepartList();
          resetPercent();
        }
        Api.msg(res.msg,'success', 50,function(){
          is_department_name_btn.value = false;
          editDepartmentNameDialogVisible.value = false;
        });
      });
    }
    // 管理子部门
    const departmentDialog = (row) => {
      getChildDepartment(row);
      departmentDialogVisible.value = true;
    }
    // 获取子部门列表
    const getChildDepartment = (row) => {
      proxy.$post('department/getChildDepartment', row).then((res)=>{
        if (res.code == 0) {
          _this.department_child_list = res.data;
          parent_id.value = res.parent_id;
          parent_name.value = res.parent_name;
        }else {
          Api.msg(res.msg, 'error', 50);
        }
      });
    }
    // 添加子部门
    const addDepartmentDialog = () => {
      _this.row.parent_id = parent_id.value;
      _this.row.parent_name = parent_name.value;
      _this.row.tags_ids = [];
      _this.row.name = '';
      departmentChildDialogVisible.value = true;
    }
    // 编辑子部门
    const editDepartmentDialog = (row) => {
      _this.row = row;
      _this.row.tags_ids = row.tags_ids;
      departmentChildDialogVisible.value = true;
    }
    // 保存子部门信息
    const saveDepartment = () =>{
      let row = _this.row;
      if(!row.name){
        Api.msg('请填写部门名称！','error', 50);
        return false;
      }
      if(!row.tags_ids){
        Api.msg('请选择部门职能！','error', 50);
        return false;
      }
      proxy.$post('department/saveChildDepartment',row).then((res)=>{
        if(res.code==0||res.code==200){
          Api.msg(res.msg,'success', 50,function(){
            getChildDepartment({id:parent_id.value,name:parent_name.value});
            departmentChildDialogVisible.value = false;
          });
        }else {
          Api.msg(res.msg, 'error', 50);
        }
      });
    }
    // 删除子部门
    const deleteDepartmentChild = (row)=>{
      Api.confirm('是否删除此部门?',()=>{
        proxy.$post('department/delDepartment',{
          id:row.id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getChildDepartment({id:parent_id.value,name:parent_name.value});
            Api.msg(res.msg,'success', 50);
          }else {
            Api.msg(res.msg, 'error', 50);
          }
        });
      });
    }
    // 管理员工
    const staffDialogVisible = ref(null)
    const openStaffDialog = (row) => {
      staffDialogVisible.value.getStaffList(row.company_id, row.id);
      staffDialogVisible.value.initData(row.company_id, row.id);
      staffDialogVisible.value.dialogVisible = true;
    }
    ///// End ////////////////////////////////////////////////
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -2;
      context.emit("updatePercent", data)
    }
    //删除部门
    const handleDelete = (row)=>{
      Api.confirm('是否删除此部门?',()=>{
        proxy.$post('department/delDepartment',{
          id:row.id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getDepartList();
            resetPercent();
            Api.msg(res.msg,'success', 50);
          }else {
            Api.msg(res.msg, 'error', 50);
          }
        });
      });
    }
    // 格式化部门职位显示
    function formatter(row){
      if(row.is_guandai==1){
        return row.staff_position+'/管理代表者'
      }else{
        return row.staff_position
      }
    }

    return {
      ...toRefs(_this),handleDelete,formatter,resetPercent,getDepartList,
      ////////////////////////////////////////////////////////////////////////////////////////////////
      dialogVisible,openDialog,
      departmentDialogVisible,departmentDialog,departmentChildDialogVisible,parent_id,parent_name,
      getChildDepartment,addDepartmentDialog,editDepartmentDialog,saveDepartment,deleteDepartmentChild,
      staffDialogVisible,openStaffDialog,
      editDepartmentNameDialogVisible,editDepartmentNameDialog,saveDepartmentName,is_department_name_btn,
    }
  }
}
</script>

<style scoped>
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  z-index: 1000;
}
.progress-bar-inner {
  position: relative;
  top: 30%;
  left: 50%;
  width: 300px;
  height: 200px;
  background-color: #ffffff;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  padding-top: 30px;
  z-index: 1001;
}
.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}
.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
/* 中间部分 */
form {
  margin-top: 24px;
  margin-bottom: 24px;
}
form > .flex + div {
  margin-top: 16px;
}
.input {
  background: #F5F6FA;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
}
.input + div {
  margin-left: 12px;
  background: rgba(48, 97, 242, .1);
  color: var(--color-text-blue);
  font-size: 16px;
  width: 5em;
  cursor: pointer;
}
.input + div i {
  display: inline-block;
  transition: transform .3s;
}
.trans180 {
  transform: rotate(180deg);
  transition: transform .3s;
}
.input .el-select {
  width: 100px;
  height: 24px;
  line-height: 24px;
  border-right: 1px solid #9D9FA6;
  margin-top: -5px;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  --el-select-border-color-hover: transparent;
}
.input :deep(.el-input) {
  line-height: 24px;
  height: 24px;
  border-color: transparent;
}
.input :deep(.el-input__wrapper) {
  background: transparent;
  box-shadow: none;
}
.input :deep(.el-select .el-input.is-focus .el-input__wrapper),
.input :deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: none !important;
}
.input input {
  height: 36px;
  line-height: 36px;
  border: none;
  background: transparent;
  padding-left: 1.19vw;
}
.input i {
  width: 50px;
  height: 36px;
  font-size: 22px;
  background: var(--color-text-blue);
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
button {
  width: 64px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  border: 0;
  margin-left: 8px;
  cursor: pointer;
}
button[type='submit'] {
  background: var(--color-text-blue);
  color: #fff;
}
button[type='reset'] {
  background: #F5F6FA;
  color: #161823;
}
.prudence {
  background: #E1EDFA;
  border: 1px solid #CFD2E6;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  margin: 15px 0;
}
ul {
  height: 420px;
  overflow: hidden;
  margin: 15px 0;
}
li {
  background: #F5F6FA;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
  padding: 0 6px 0 18px;
  box-sizing: border-box;
}
.tit {
  width: 65%;
  font-size: 16px;
  font-family: 'PingFang-SC-Medium';
}
.num {
  width: calc(35% - 48px);
  font-size: 14px;
  font-family: 'PingFang-SC-Medium';
}
.addbtn {
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  margin-top: 6px;
  margin-left: 5px;
}
.picked .addbtn {
  background: rgba(48, 97, 242, .2);
}
.flex-b-c > span {
  width: 100px;
  height: 28px;
  line-height: 28px;
  background: var(--color-text-blue);
  color: #fff;
  font-size: 18px;
  font-family: 'PingFang-SC-Bold';
  text-align: center;
  border-radius: 4px;
  cursor: default;
}
/* 下 */
.foot_msg button {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: 'PingFang-SC-Bold';
  color: var(--color-text-white);
  background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
  border-radius: 4px;
  border: none;
  width: 200px;
  margin: calc((4.1vw - 40px) / 2) 0;
}
/* 弹出框 */
ol {
  margin: 15px 0;
}
.cont li.picked .addbtn {
  background: var(--color-text-blue);
}
.btn.cancle {
  background: transparent;
  color: #9D9FA6;
  font-size: 16px;
}
.btn {
  background: var(--color-text-blue);
  color: #fff;
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
/* 覆盖默认的关闭图标样式 */
:deep(.el-dialog__headerbtn .el-dialog__close) {
  /* 你可以修改图标颜色、大小等属性 */
  color: red;
  font-size: 20px;
}
:deep(.el-dialog__footer) {
  text-align: center;
}
:deep(.el-dialog__body) {
  padding: calc(var(--el-dialog-padding-primary)) var(--el-dialog-padding-primary);
}
</style>


