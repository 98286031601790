<!-- 企业的信息展示 左+右 -->
<template>
  <div class="flex pr">
    <!-- 左边 -->
    <div class="pr">
      <div class="enterpriseName">
        {{ projectInfo.project_name }}
        <router-link to="/change"><i class="icon-qiehuan1 f20"></i></router-link>
      </div>
      <div class="flex">
        <ul>
          <li :class="template_id==item.id?'active':(item.percent==100?'normal':'')" v-for="(item, index) in list" :key="index">
            <router-link to="" class="">
              <span class="num">{{ index + 1 }}</span>
              <div class="limsg">
                <div class="f24" @click="changeNav(item.id,index)">
                  {{ item.name }}
                  <!-- <span class="orange f18">预览</span> -->
                </div>
                <div>
                  <el-progress :percentage="item.percent"></el-progress>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
<!--        <div class="tip">
          <img src="@/assets/img/home/tip_nail.png" alt="" class="pab">
          <div class="f14 color_9">请根据提示填写信息</div>
          <div class="f14 color_9">信息清晰可见，内容真实有效</div>
          <div class="f14 color_9">部分信息提交后不允许修改</div>
          <div class="f14 color_9">如需更改请联系<span class="blue">咨询工具客服</span></div>
        </div>-->
      </div>
    </div>
    <!-- 右边 -->
    <div>
      <keep-alive>
        <router-view @updatePercent="updatePercent"></router-view>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import {ref, reactive, toRefs, getCurrentInstance, watch, onBeforeMount} from 'vue'
import {useRoute, useRouter} from 'vue-router'// eslint-disable-next-line no-unused-vars
import { showLoading, hideLoading } from '@/utils/loading'

export default {
  name: 'Home',
  setup() {
    var route = useRoute()// eslint-disable-line no-unused-vars
    var router = useRouter()// eslint-disable-line no-unused-vars
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    let defaultData = reactive({
      list: [],
      showA: true,
      companyInfo: {},
      projectInfo: {},
    });
    let template_id = ref(0)
    let isShow = ref(false)
    let project_id = Api.sessionGet('project_id')
    let fixed_menu = Api.fixed_menu;
    //获取左侧模板目录
    const getLeftList = () => {
      proxy.$post('template/getFormList', {
        project_id: project_id,
      }).then(function (response) {
        defaultData.companyInfo = response.companyInfo;
        defaultData.projectInfo = response.projectInfo;
        defaultData.list = response.data;
        if (route.path == '/home') {
          let path = ''
          defaultData.list.forEach((v) => {
            if (path == '' && v.percent < 100) {
              template_id.value = v.id;
              path = fixed_menu[v.id];
              if (v.id > 0) path = '/home/information/' + v.id;
            }
          })
          if (path != '' && path != '/home') {
            router.push(path)
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
    onBeforeMount(() => {
      getLeftList();
    })

    //打包下载
    const packageProject = () => {
      showLoading();
      proxy.$post('project/packageProject', {
        project_id: project_id,
      }).then(res => {
        if (res.code == 0) {
          hideLoading();
          window.location.href = Api.WEB_SITE + res.data;
        } else {
          Api.msg(res.msg, 'error', 50);
        }
      })
    }
    //改变模板选择
    const changeNav = (id, index) => {
      let path = '';
      if (index > 0) {
        for (var i=0;i<defaultData.list.length;i++){
          //if (id>-8){
            if (defaultData.list[i]['percent'] == 100 && defaultData.list[i]['id'] == id && id!= -33) {
              path = fixed_menu[defaultData.list[i]['id']];
              if (defaultData.list[i]['id'] > 0) path = '/home/information/' + defaultData.list[i]['id'];
              if (path != '' && path != '/home') {
                router.push(path);
              }
              return false;
            }
            if (defaultData.list[i]['percent']<100 && id!=-3){
              if (defaultData.list[i]['msg'] != '' || defaultData.list[i]['msg'] != undefined || defaultData.list[i]['msg'] != null) {
                Api.msg(defaultData.list[i]['msg']);
              }else {
                Api.msg(defaultData.list[i]['name'] + ' 资料不完整，请补充完整!');
              }
              path = fixed_menu[defaultData.list[i]['id']];
              if (defaultData.list[i]['id'] > 0) path = '/home/information/' + defaultData.list[i]['id'];
              if (path != '' && path != '/home') {
                router.push(path);
              }
              return false;
            }
          //}
        }
      }
      template_id.value = id;
      if (id == 0) {
        router.push('/home/baseinfo');
      }else if(id>0){
        router.push('/home/information/' + template_id.value);
      }else if (id == -3) {
        for (var j=0;j<defaultData.list.length;j++){
          if (defaultData.list[j]['id']<0 && defaultData.list[j]['id']>-8 && defaultData.list[j]['percent']<100 && defaultData.list[j]['id']!=-3){
            if (defaultData.list[j]['msg'] != '' || defaultData.list[j]['msg'] != undefined || defaultData.list[j]['msg'] != null) {
              Api.msg(defaultData.list[j]['msg'], 'error', 50);
            }else {
              Api.msg(defaultData.list[j]['name'] + ' 资料不完整，请补充完整!', 'error', 50);
            }
            path = fixed_menu[defaultData.list[j]['id']];
            if (path != '' && path != '/home') {
              router.push(path);
            }
          }else {
            path = fixed_menu[id];
            router.push(path);
          }
        }
      }else{
        path = fixed_menu[id];
        router.push(path);
      }
    }
    //显示、隐藏
    const changeIsShow = (n) => {
      isShow.value = n;
    }
    //隐藏
    const cancel = () => {
      isShow.value = false;
    }
    const create = () => {
      cancel();
    }
    //更改进度
    const updatePercent = (data) => {
      let template_id = data.template_id;// eslint-disable-line no-unused-vars
      getLeftList();
      //跳转
      /*for (var i=0;i<defaultData.list.length;i++){
        if (defaultData.list[i]['percent']<100){
          let path = '';
          path = fixed_menu[defaultData.list[i]['id']];
          if (defaultData.list[i]['id'] > 0) path = '/home/information/' + defaultData.list[i]['id']
          if (path != '' && path != '/home') {
            router.push(path);
            return false;
          }
        }
      }*/
    }
    //监听路由变化
    watch(() => router.currentRoute.value.path, (toPath) => {
      if (toPath == '/home/externalList') {
        template_id.value = -1;
      }else if (toPath == '/home/department') {
        template_id.value = -2;
      }else if (toPath == '/home/viewProject') {
        template_id.value = -3;
      }else if (toPath == '/home/flow') {
        template_id.value = -4;
      }else if (toPath == '/home/address') {
        template_id.value = -5;
      }else if (toPath == '/home/qualification') {
        template_id.value = -6;
      }else if (toPath == '/home/equipmentSchedule') {
        template_id.value = -7;
      }else if (toPath.indexOf('/home/information/') != -1) {
        template_id.value = route.params.template_id || 0
      }
    }, {immediate: true, deep: true});

    return {
      isShow,
      template_id,
      ...toRefs(defaultData),
      changeNav,
      changeIsShow,
      cancel,
      create,
      updatePercent,
      packageProject,
    }
  }
}
</script>

<style scoped>
.flex.pr {
  padding-top: 3.54vw;
  padding-left: 6.77vw;
  padding-right: 4.16vw;
  background: url(@/assets/img/home/home_bg1.png) no-repeat right top/100vw 100vh;
  z-index: 0;
}

/* 左边 */
.flex > .pr {
  width: 32.81vw;
  height: calc(100vh - 3.54vw);
}

.flex>.pr>.flex{flex-direction:column; }
ul{margin-top: 3.8vw;
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column dense;}
li{
  /*margin-bottom: 20px;*/
  padding: 0;
  background: none;
  display: flex;
}

.enterpriseName {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  background: linear-gradient(0deg, #AAA0FA 0%, #3061F2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.f20 {
  font-size: 22px;
}

ul {
  margin-top: 3.8vw;
}

li > a {
  margin-bottom: 20px;
  padding: 0;
  background: none;
  display: flex;
}

.num {
  color: rgba(48, 97, 242, .5);
  font-size: 24px;
  width: 40px;
}

.active .num {
  color: rgba(48, 97, 242, 1);
}

.f24, .f24 > a {
  color: #9D9FA6;
}

.active .f24, .active .f24 > a {
  color: var(--color-text-blue);
}

.orange {
  margin-left: 20px;
}

:deep(.el-progress-bar__inner) {
  background: linear-gradient(90deg, rgba(48, 97, 242, .3) 0%, rgba(170, 160, 250, .3) 100%);
}

.active :deep(.el-progress-bar__inner) {
  background: linear-gradient(90deg, rgba(48, 97, 242, 1) 0%, rgba(170, 160, 250, 1) 100%);
}

.tip {
  position: absolute;
  right: 0;
  top: 100px;
  padding: 1vw;
  width: calc(100% - 220px);
  max-width: 230px;
  border-radius: 20px;
  box-shadow: 0px 0px 9px 1px rgba(1, 27, 70, 0.1);
  box-sizing: border-box;
}

.tip img {
  position: absolute;
  left: -8px;
  top: -8px;
  width: 24px;
  height: 24px;
}

/* 右边 */
.flex > .pr + div {
  width: calc(100% - 32.81vw);
  margin-left: 4vw;
  position: relative;
}

.flex > .pr + div::before {
  content: '';
  position: absolute;
  right: -3.64vw;
  top: -2.6vw;
  width: 7.24vw;
  height: 7.44vw;
  background: url(@/assets/img/home/home_bg3.png) no-repeat left top /100%;
  z-index: 1;
}

.normal .num {
  color: #000;
}

.normal .f24 {
  color: #000;
}
</style>
