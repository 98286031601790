<template>
  <el-dialog v-model="dialogVisble" title="生产设备列表" @close="handleClose" width="800">
  <div>
    <!-- 搜索输入框 -->
    <el-form ref="formScreening" @submit.native.prevent>
      <div class="flex">
        <div class="input">
        <el-input type="text" v-model="search_word" placeholder="输入要查找的设备名称" />
        </div>
        <el-button type="primary" @click="fetchEquipment">搜 索</el-button>
      </div>
    </el-form>
    <!-- 设备列表 -->
    <el-table v-if="equipment.length>0" :data="equipment" border show-header style="margin-top: 15px; width: 100%;">
      <el-table-column label="名称" width="350" align="center">
        <template #default="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="age" fixed="right" label="操作" width="100" align="center">
        <template #default="scope">
          <div class="addbtn" v-show="scope.row.is_add" @click="selectEquipment(scope.row)"><i class="icon-tianjia"></i></div>
          <div class="delbtn" v-show="scope.row.is_del" @click="deleteEquipment(scope.row)"><i class="icon-jianqu"></i></div>
        </template>
      </el-table-column>
    </el-table>
    <el-empty v-else description="暂无设备信息！"></el-empty>
    <!-- 分页控制 -->
    <el-pagination v-if="equipment.length>0" @current-change="handleCurrentChange"
                   :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="allCount">
    </el-pagination>
  </div>
  </el-dialog>
</template>

<script>
import {ref, onMounted, getCurrentInstance, watch} from 'vue';

export default {
  props: {
    visibleDialog: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const {proxy} = getCurrentInstance()
    //const Api = proxy.$commonApi
    const search_word = ref('');
    const currentPage = ref(1);
    const pageSize = ref(10);
    const equipment = ref([]);
    const dialogVisble = ref(false);
    const allCount = equipment.value.length;

    onMounted(()=>{
      //fetchEquipment();
    });
    // 获取设备列表
    async function fetchEquipment() {
      proxy.$post("/equipment/index",{
        search_word:search_word.value,
        page:currentPage.value,
        equipment_type:2,
      }).then((res)=>{
        equipment.value = res.data;
      });
    }

    function selectEquipment(param) {
      for (let i = 0; i < equipment.value.length; i++) {
        if (equipment.value[i].id == param.id) {
          equipment.value[i].is_add = false;
          equipment.value[i].is_del = true;
        }
      }
      emit('setEquipment', {id:param.id,name:param.name});
    }

    function deleteEquipment(param) {
      for (let i = 0; i < equipment.value.length; i++) {
        if (equipment.value[i].id == param.id) {
          equipment.value[i].is_add = true;
          equipment.value[i].is_del = false;
        }
      }
      emit('deleteEquipment', {id:param.id,name:param.name});
    }

    //切换页码
    const handleCurrentChange = (page)=>{
      currentPage.value = page;
      fetchEquipment();
    }

    const handleClose = () => {
      console.log('关闭');
    };
    watch(dialogVisble, () => {
      fetchEquipment();
    });

    return {
      search_word, currentPage, pageSize,allCount,handleCurrentChange,
      equipment, dialogVisble,
      fetchEquipment,
      selectEquipment,deleteEquipment,
      handleClose
    };
  }
};
</script>

<style scoped>
.input :deep(.el-input) {
  background: #F5F6FA;
  border-radius: 4px;
  height: 32px;
  line-height: 32px;
  width: 200px;
  border: 1px solid #9D9FA6;
  margin-right: 3px;
}
:deep(.el-input__wrapper){
  border:1px solid #9D9FA6;
  background:transparent;
  box-shadow:none;
}
:deep(.el-input.is-focus .el-input__wrapper),
:deep(.el-input__wrapper.is-focus){
  box-shadow:none !important;
}
.addbtn{
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius:4px;
  margin-top:6px;
  margin-left:5px;
  cursor:pointer;
}
.picked .addbtn{
  background: rgba(48, 97, 242, .2);
}
.delbtn{
  background: var(--color-text-red);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius:4px;
  margin-top:6px;
  margin-left:5px;
  cursor:pointer;
}
</style>
