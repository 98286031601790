import  {createApp}  from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from '@/store/index'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/css/font/iconfont.css'
import '@/assets/css/font/iconfont.js'
import commonApi from '@/store/commonApi.js';
import Request from '@/utils/axios'
// import Vant from 'vant'
// import 'vant/lib/index.css'
import layer from '@layui/layer-vue';
import '@layui/layer-vue/lib/index.css';

commonApi.getServerDate().then(()=>{
  const app = createApp(App)
  app.use(router)
  app.use(store)
  app.use(commonApi)
  app.config.globalProperties['$commonApi'] = commonApi;
  app.use(Request)
  app.use(layer);

  for (const [key,component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key,component)
  }

  app.use(ElementPlus, { locale })
  // Object.keys(ElIconModules).forEach(function (key) {
  //   app.component(key, ElIconModules[key])
  // })
  app.mount('#app')
})
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}




