<!-- 企业地址 -->
<template>
  <RightSide>
    <template v-slot:tit>企业地址</template>
    <template v-slot:tit_right>
      <!-- <div class="flex_1 flex--c">
        进度 <el-progress class="ml-15" :percentage="10" :stroke-width="16" define-back-color="#F5F6FA"></el-progress>
      </div> -->
    </template>
    <template v-slot:write_msg>
      <el-form ref="addressForm" label-width="80px">
        <div class="flex">
          <span>注册地址：</span>
          <div class="text">
            <Linkage
                :first_id="addressInfo.province_id"
                :second_id="addressInfo.city_id"
                :third_id="addressInfo.country_id"
                :length="3"
                :flag="'regist'"
                :key="0"
                @changeEvent="changeArea"
            >
              <div class="fill"></div>
            </Linkage>
            <br>
            <el-input style="width: 450px;" v-model="addressInfo.address" placeholder="请输入详细注册地址"></el-input>
            <div class="f14 red">注：请填写营业执照中的地址信息</div>
          </div>

        </div>
        <div class="flex">
          <span>办公地址：</span>
          <div class="text">
            <Linkage
                :first_id="addressInfo.province_id1"
                :second_id="addressInfo.city_id1"
                :third_id="addressInfo.country_id1"
                :length="3"
                :flag="'office'"
                :key="1"
                @changeEvent="changeArea">
            </Linkage>
            <el-checkbox v-model="checked" @click="copyAddress" style="margin-left: 5px;">同步地址</el-checkbox>
            <br>
            <el-input style="width: 450px; float: left; margin-right: 10px;" v-model="addressInfo.address1" placeholder="请输入详细办公地址"></el-input>
            <el-button type="primary" style="width: auto; margin-top: 10px;" circle @click="addOfficeAddress">
              <el-icon>
                <Plus/>
              </el-icon>
            </el-button>
            <div class="f14 red" style="clear:both;">注：实际办公的地址，如与注册地址一致，则进行勾选即可，如不一致，请另行选择填写</div>
          </div>
        </div>
        <div class="flex" v-for="(it,index) in addressInfo.office_address_arr" :key="index">
          <span>{{ it.title }}：</span>
          <div class="text">
            <Linkage
                :first_id="it.province_id"
                :second_id="it.city_id"
                :third_id="it.country_id"
                length="3"
                :flag="'office_address_other'+index"
                :key="index"
                @changeEvent="changeArea">
              <div class="fill"></div>
            </Linkage>
            <br>
            <el-input style="width: 450px;" v-model="it.address" placeholder="请输入详细地址"></el-input>
            <el-button type="danger" style="width: auto; margin-left: 9px;" circle @click="deleteOfficeAddress(index)">
              <el-icon>
                <Delete/>
              </el-icon>
            </el-button>
          </div>
        </div>
        <div class="flex">
          <span style="width: 56px;">地 址：</span>
          <div class="text" style="width: 750px;">
            <!--<el-button type="primary" style="width: auto;" circle @click="addAddress">
                <el-icon>
                  <Plus />
                </el-icon>
              </el-button>-->
            <!--<el-button type="primary" style="width: 130px;" @click="copyOfficeAddress">同步办公地址</el-button>-->
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(1)">添加生产地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(2)">添加经营地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(3)">添加分公司地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(4)">添加分支机构地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(5)">添加连锁店地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(6)">添加加工厂地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(7)">添加建筑施工地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(8)">添加工程勘测地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(9)">添加建筑监理地址</el-button>
            <el-button type="primary" style="width: 150px; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(10)">添加房地产开发地址</el-button>
            <el-button type="primary" style="width: auto; margin-left: 0px; margin-right: 9px; margin-bottom: 5px;" @click="addAddress(11)">添加系统集成（安防）地址</el-button>
            <div class="f14 red">注：产品生产所在地，如与注册地、办公地不一致，会形成多场所现象，认证审核过程中，需要对不同场所均进行审核，请确认后谨慎填写。销售型企业请填写经营地址！</div>
          </div>
        </div>
        <div class="flex" v-for="(it,index) in addressInfo.product_config" :key="index">
          <span style="width: 100px;">{{ it.title }}{{ index + 1 }}</span>
          <div class="text">
            <Linkage
                :first_id="it.province_id"
                :second_id="it.city_id"
                :third_id="it.country_id"
                length="3"
                :flag="index"
                :key="index"
                @changeEvent="changeArea">
              <div class="fill"></div>
            </Linkage>
            <br>
            <el-input style="width: 450px;" v-model="it.address" placeholder="请输入详细地址"></el-input>
            <el-button type="danger" style="width: auto; margin-left: 9px;" circle @click="deleteAddress(index)">
              <el-icon>
                <Delete/>
              </el-icon>
            </el-button>
          </div>
        </div>
      </el-form>
    </template>
    <template v-slot:foot_msg>
      <button @click="addressModify" style="float:right;">保存</button>
    </template>
  </RightSide>
</template>
<script>
import {reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import Linkage from '@/components/common/Linkage.vue'
// import { layer } from '@layui/layer-vue'

export default {
  name: 'Addresses',
  components: {
    RightSide,
    Linkage,
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const addressForm = ref(null);

    //公共数据
    var project_id = Api.sessionGet('project_id');
    let _this = reactive({
      action: Api.UPLOAD_URL,
      site: Api.WEB_SITE,
      checked: false,
      project_id: project_id,
      addressInfo: [{province_id: 0, city_id: 0, country_id: 0, address: ''}]
    });
    onMounted(() => {
      getAddressList()
    })
    //获取地址信息
    const getAddressList = () => {
      let defaut = {
        province_id: 0,
        city_id: 0,
        country_id: 0,
        address: '',
        province_id1: 0,
        city_id1: 0,
        country_id1: 0,
        address1: '',
        office_address_arr: [],
        product_config: [],
        business_address_flag: 0,
      }
      proxy.$post('company/addressList', {
        company_id: _this.company_id
      }).then(res => {
        _this.addressInfo = res || defaut
      })
    }
    //更新企业信息
    const addressModify = () => {
      let addressInfo = _this.addressInfo
      if (!addressInfo.country_id) {
        Api.msg('请选择完整的注册地址！');
        return false;
      }
      if (!addressInfo.address) {
        Api.msg('请填写详细的注册地址！');
        return false;
      }
      if (!addressInfo.country_id1) {
        Api.msg('请选择完整的办公地址！');
        return false;
      }
      if (!addressInfo.address1) {
        Api.msg('请填写详细的办公地址！');
        return false;
      }
      if (!addressInfo.business_address_flag) {
        Api.confirm('经营地址是否和办公地址一致?',()=>{
          addressInfo.business_address_flag = 1;
        },()=>{
          addressInfo.business_address_flag = 2;
          addAddress(2);
        });
        return false;
      }
      var productAddr = addressInfo.product_config;
      /*if(productAddr.length==0){
        Api.msg('请添加生产地址！');return false;
      }*/
      if (productAddr.length > 0) {
        var is_complete = true
        var key = 0;
        productAddr.forEach((v, k) => {
          if (!v.province_id || !v.city_id || !v.country_id || !v.address) {
            is_complete = false
            key = k + 1
          }
        })
        if (is_complete === false) {
          Api.msg('请填写完整' + productAddr[key - 1].title + key + '！');
          return false;
        }
      }
      var officeAddr = addressInfo.office_address_arr;
      if (officeAddr.length > 0) {
        var is_complete_office = true
        var office_key = 0;
        officeAddr.forEach((v, k) => {
          if (!v.province_id || !v.city_id || !v.country_id || !v.address) {
            is_complete_office = false
            office_key = k + 1
          }
        })
        if (is_complete_office === false) {
          Api.msg('请填写完整' + officeAddr[office_key - 1].title + office_key + '！');
          return false;
        }
      }
      proxy.$post('company/addressUpdate', addressInfo).then(res => {
        Api.msg(res.msg, 0, '', function () {
          if (res.code == 0) {
            resetPercent()
          }
        })
      })
    }
    // 添加办公地址
    const addOfficeAddress = () => {
      let item = {office_flag:0, title: '办公地址', province_id: 0, city_id: 0, country_id: 0, address: ''}
      var office_address_arr = _this.addressInfo.office_address_arr || []
      item.office_flag = office_address_arr.length;
      item.title = '办公地址' + (office_address_arr.length+1);
      office_address_arr.push(item);
      _this.addressInfo.office_address_arr = office_address_arr
    }
    // 删除办公地址
    const deleteOfficeAddress = (index) => {
      var office_address_arr = _this.addressInfo.office_address_arr || []
      office_address_arr.splice(index, 1);
    };
    // 添加地址
    const addAddress = (params) => {
      let item = {product_flag: 0, title: '生产地址', province_id: 0, city_id: 0, country_id: 0, address: ''}
      var product_config = _this.addressInfo.product_config || []
      item.product_flag = params;
      switch (params) {
        case 1:
          item.title = '生产地址';
          break;
        case 2:
          item.title = '经营地址';
          _this.addressInfo.business_address_flag = 2;
          break;
        case 3:
          item.title = '分公司地址';
          break;
        case 4:
          item.title = '分支机构地址';
          break;
        case 5:
          item.title = '连锁店地址';
          break;
        case 6:
          item.title = '加工厂地址';
          break;
        case 7:
          item.title = '建筑施工地址';
          break;
        case 8:
          item.title = '工程勘测地址';
          break;
        case 9:
          item.title = '建筑监理地址';
          break;
        case 10:
          item.title = '房地产开发地址';
          break;
        case 11:
          item.title = '系统集成（安防）地址';
          break;
      }
      product_config.push(item);
      _this.addressInfo.product_config = product_config
    }
    // 删除地址
    const deleteAddress = (index) => {
      var product_config = _this.addressInfo.product_config || []
      product_config.splice(index, 1);
      if (_this.addressInfo.business_address_flag == 2) {
        _this.addressInfo.business_address_flag = 0;
      }
    };
    //地区
    //地区选择回调
    const changeArea = (data, flag) => {
      let addressInfo = _this.addressInfo
      var v1 = data[0] || 0
      var v2 = data[1] || 0
      var v3 = data[2] || 0
      if (flag == 'regist') {
        addressInfo.province_id = v1
        addressInfo.city_id = v2
        addressInfo.country_id = v3
      } else if (flag == 'office') {
        addressInfo.province_id1 = v1
        addressInfo.city_id1 = v2
        addressInfo.country_id1 = v3
      }else if (flag.toString().indexOf('office_address_other') !== -1) {
        var office_address_arr = _this.addressInfo.office_address_arr || []
        var office_row = office_address_arr[Api.replaceNonDigits(flag)] || []
        office_row.province_id = v1
        office_row.city_id = v2
        office_row.country_id = v3
      }else {
        var product_config = _this.addressInfo.product_config || []
        var row = product_config[flag] || []
        row.province_id = v1
        row.city_id = v2
        row.country_id = v3
      }
      _this.addressInfo = addressInfo
    }
    // 复选框点击事件
    const copyAddress = () => {
      var checked = _this.checked;
      var addressInfo = _this.addressInfo;
      if (checked == false) {
        _this.addressInfo.province_id1 = addressInfo.province_id;
        _this.addressInfo.city_id1 = addressInfo.city_id;
        _this.addressInfo.country_id1 = addressInfo.country_id;
        _this.addressInfo.address1 = addressInfo.address;
      }
    }
    // 同步办公地址
    const copyOfficeAddress = () => {
      var addressInfo = _this.addressInfo;
      let item = {province_id: addressInfo.province_id, city_id: addressInfo.city_id, country_id: addressInfo.country_id, address: addressInfo.address};
      var product_config = _this.addressInfo.product_config || [];
      product_config.push(item);
      _this.addressInfo.product_config = product_config;
    }

    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -5;
      context.emit("updatePercent", data);
    }
    return {
      ...toRefs(_this),
      addAddress,
      getAddressList,
      addressModify,
      changeArea,
      copyAddress,
      resetPercent,
      copyOfficeAddress,
      deleteAddress,
      addressForm,
      addOfficeAddress,deleteOfficeAddress,
    }
  }
}
</script>

<style scoped>
/* 中间部分 */
form {
  margin-top: 24px;
  margin-bottom: 24px;
}

form > .flex + div {
  margin-top: 16px;
}

.input {
  background: #F5F6FA;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
}

.input + div {
  margin-left: 12px;
  background: rgba(48, 97, 242, .1);
  color: var(--color-text-blue);
  font-size: 16px;
  width: 5em;
  cursor: pointer;
}

.input + div i {
  display: inline-block;
  transition: transform .3s;
}

.trans180 {
  transform: rotate(180deg);
  transition: transform .3s;
}

.input .el-select {
  width: 100px;
  height: 24px;
  line-height: 24px;
  border-right: 1px solid #9D9FA6;
  margin-top: -5px;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  --el-select-border-color-hover: transparent;
}

.input :deep(.el-input) {
  line-height: 24px;
  height: 24px;
  border-color: transparent;
}

.input :deep(.el-input__wrapper) {
  background: transparent;
  box-shadow: none;
}

.input :deep(.el-select .el-input.is-focus .el-input__wrapper),
.input :deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: none !important;
}

.input input {
  height: 36px;
  line-height: 36px;
  border: none;
  background: transparent;
  padding-left: 1.19vw;
}

.input i {
  width: 50px;
  height: 36px;
  font-size: 22px;
  background: var(--color-text-blue);
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

button {
  width: 64px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  border: 0;
  /*margin-left:8px;*/
  cursor: pointer;
}

button[type='submit'] {
  background: var(--color-text-blue);
  color: #fff;
}

button[type='reset'] {
  background: #F5F6FA;
  color: #161823;
}

.prudence {
  background: #E1EDFA;
  border: 1px solid #CFD2E6;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  margin: 15px 0;
}

ul {
  height: 420px;
  overflow: hidden;
  margin: 15px 0;
}

li {
  background: #F5F6FA;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
  padding: 0 6px 0 18px;
  box-sizing: border-box;
}

.tit {
  width: 65%;
  font-size: 16px;
  font-family: 'PingFang-SC-Medium';
}

.num {
  width: calc(35% - 48px);
  font-size: 14px;
  font-family: 'PingFang-SC-Medium';
}

.addbtn {
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  margin-top: 6px;
  margin-left: 5px;
}

.picked .addbtn {
  background: rgba(48, 97, 242, .2);
}

.flex-b-c > span {
  width: 100px;
  height: 28px;
  line-height: 28px;
  background: var(--color-text-blue);
  color: #fff;
  font-size: 18px;
  font-family: 'PingFang-SC-Bold';
  text-align: center;
  border-radius: 4px;
  cursor: default;
}

/* 下 */
.foot_msg button {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: 'PingFang-SC-Bold';
  color: var(--color-text-white);
  background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
  border-radius: 4px;
  border: none;
  width: 200px;
  margin: calc((4.1vw - 40px) / 2) 0;
}

/* 弹出框 */
ol {
  margin: 15px 0;
}

.cont li.picked .addbtn {
  background: var(--color-text-blue);
}

.blue.hand {
  border: 0;
  background: none;
}

.btn.cancle {
  background: transparent;
  color: #9D9FA6;
  font-size: 16px;
}

.btn {
  background: var(--color-text-blue);
  color: #fff;
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
}

.flex > span {
  font-size: 14px;
  color: #6D6E73;
  margin-right: 10px;
  line-height: 32px;
}

/* uploader上传图片 样式重置 */
.avatar-uploader :deep(.el-upload) {
  background: #fff;
  border: 1px dashed #CFD2E6;
  width: 7.29vw;
  height: 4.58vw;
  margin-right: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.el-form-item__content .bg_56a {
  background: none;
}

.avatar-uploader :deep(.el-upload):hover {
  border-color: #409EFF;
}

.icon-xiangji {
  color: var(--color-text-blue);
  font-size: 30px;
}

.avatar {
  width: 7.29vw;
  height: 4.58vw;
  display: block;
}
</style>
