<!-- 文件预览 -->
<template>
  <RightSide>
    <template v-slot:tit>
      相关资质
    </template>
    <template v-slot:write_msg>
      <div class="fill bg_56a p10" style="width: 90%;">
        <div class="flex--c">
          <el-upload class="avatar-uploader"
                     :action="action"
                     :headers="getHeader()"
                     :show-file-list="false"
                     accept="image/png,image/gif,image/jpg,image/jpeg"
                     :multiple="true"
                     :before-upload = "handleBeforeUpload"
                     :on-success="handleAvatarSuccess"
                     :data="{id:99999,ptype:'temptags'}"
          >
            <img class="avatar" style="display: none;">
            <div class="text_center">
              <i class="blue icon-xiangji avatar-uploader-icon"></i>
              <div class="blue f14">上传资质</div>
            </div>
          </el-upload>
          <span class="color_9" style="width: 80%;">请根据认证范围上传相关资质，例如：CCC证书、许可证、产品检测报告等相关内容，图片格式不限，可上传多张，单张大小不得超过2M。</span>
        </div>
        <el-carousel v-if="list.length>0"  indicator-position="outside" arrow="always" trigger="click" height="9.2vw" :autoplay=false>
          <el-carousel-item>
            <div v-for="(it,k) in list" :key="k" style="width: calc(100% / 5 - 10px); height:auto;margin-left: 10px;position: relative;">
              <el-image
                  v-if="Api.get_file_suff(it)=='pdf'"
                  style="width: 100%; height:100%;margin-left: 10px"
                  @click="previewImg(site+it)"
                  :src="site+'/static/index/img/pdf200.jpg'"
                  :lazy="true" >
              </el-image>
              <el-image
                  v-else
                  style="width: 100%; height:100%;margin-left: 10px;cursor: pointer;"
                  @click="previewImg(site+it)"
                  :src="site+it"
                  :lazy="true" title="点击预览">
              </el-image>
              <el-icon @click="deleteImg(it,k)" style="vertical-align: middle;color: red;position: absolute;top: 0px;right: -10px;cursor: pointer; font-size: 25px;" title="删除"><circle-close/></el-icon>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </template>
    <template v-slot:foot_msg>
      <div style="display: flex;justify-content:space-around;align-items: center;">
        <span style="color:red;">注：保存数据之前不要刷新页面否则数据会丢失</span>
        <span><el-checkbox v-model="qualification_flag" :true-label="1" :false-label="0" label="暂无资质" size="large" /></span>
        <button class="btn" @click.prevent="saveQualification">保存</button>
      </div>
    </template>
  </RightSide>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import {layer} from "@layui/layer-vue";

export default {
  name: 'Qualification',
  components: {
    RightSide,
  },
  props: {},
  setup (props,context) {
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    let qualification_flag = ref(0);
    let _this = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      list:[],
      headers:{},
    });

    onMounted(()=>{
      getListing();
    });

    //获取列表
    const getListing = ()=>{
      //var load = layer.load(0,{offset:['40%','65%']});
      proxy.$post("company/getCerList",{}).then((res)=>{
        if (res.code==0) {
          _this.list = res.data;
          qualification_flag.value = res.qualification_flag;
        }else {
          Api.msg('网络加载失败！')
        }
      });
    }
    const getHeader = ()=>{
      let headers = Api.getHeader();
      let header = {}
      header.USERKEY = headers.userKey;
      header.USERTOKEN = headers.token;
      return header
    }
    // 文件上传前触发
    const handleBeforeUpload = (file) => {
      const flag = ref(true);
      const allowType = 'jpg,jpeg,png,gif';
      const type = file.name.split(".")[1];
      //const fileType = item.name.substring(item.name.lastIndexOf('.') + 1)
      if (allowType.indexOf(type) == -1) {
        Api.msg('只能上传图片！', 'error', 25)
        flag.value = false;
        return false;
      } else if (file.size / 1024 / 1024 > 2) {
        Api.msg('上传文件大小不能超过 2MB!', 'error', 25);
        flag.value = false;
        return false;
      }
      return flag.value;
    }
    //上传成功
    function handleAvatarSuccess(response){
      if(response.code==1){
        Api.msg(response.msg)
      }else{
        _this.list.push(response.data.src);
      }
      console.log(_this.list);
    }
    //图片预览
    const previewImg = (url)=> {
      layer.open({
        type: 2,
        content: url,
        area: ['60%', '80%'],
      });
    }
    //删除图片文件
    const deleteImg = (pic_url,k)=>{
      console.log(pic_url,k);
      let allList = _this.list;
      var path = pic_url;
      allList.splice(k, 1)
      console.log(allList);
      proxy.$post('index/delImg',{
        path:path, flag:'other_qualification', allList:allList,
      }).then(res=>{
        if(res.code==0){
          _this.list = allList
        }
      })
    }

    // 保存相关资质
    const saveQualification = ()=>{
      proxy.$post('company/qualificationUpdate', {qualification_arr:_this.list,qualification_flag:qualification_flag.value}).then(res => {
        Api.msg(res.msg, 0, '', function () {
          if (res.code == 0) {
            getListing();
            resetPercent();
          }
        });
      });
    }

    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -6;
      context.emit("updatePercent", data)
    }

    return {
      ...toRefs(_this), qualification_flag, Api, resetPercent,getListing,
      previewImg,deleteImg,getHeader,handleAvatarSuccess,handleBeforeUpload,saveQualification

    }
  }
}
</script>

<style scoped>
  .fill{display: inline-block;}
  /* 中间部分 */
  form{
    margin-top:24px;
    margin-bottom:24px;
  }
  form>.flex+div{
    margin-top:16px;
  }
  .input{
    background:#F5F6FA;
    border-radius:4px;
    height:36px;
    line-height:36px;
  }
  .input+div{
    margin-left:12px;
    background: rgba(48, 97, 242, .1);
    color: var(--color-text-blue);
    font-size: 16px;
    width: 5em;
    cursor: pointer;
  }
  .input+div i{
    display: inline-block;
    transition: transform .3s;
  }
  .trans180{
    transform: rotate(180deg);
    transition: transform .3s;
  }
  .input .el-select{
    width:100px;
    height:24px;
    line-height:24px;
    border-right:1px solid #9D9FA6;
    margin-top:-5px;
    border-left-color:transparent !important;
    border-top-color:transparent !important;
    border-bottom-color:transparent !important;
    --el-select-border-color-hover: transparent;
  }
  .input :deep(.el-input){
    line-height:24px;
    height:24px;
    border-color: transparent;
  }
  .input :deep(.el-input__wrapper){
    background:transparent;
    box-shadow:none;
  }
  .input :deep(.el-select .el-input.is-focus .el-input__wrapper),
  .input :deep(.el-select .el-input__wrapper.is-focus){
    box-shadow:none !important;
  }
  .input input{
    height:36px;
    line-height:36px;
    border:none;
    background:transparent;
    padding-left:1.19vw;
  }
  .input i{
    width:50px;
    height:36px;
    font-size:22px;
    background: var(--color-text-blue);
    color:#fff;
    text-align: center;
    display: inline-block;
    border-radius:0 4px 4px 0;
    cursor:pointer;
  }
  button{
    width:64px;
    height:32px;
    line-height:32px;
    text-align: center;
    border-radius:4px;
    font-size:16px;
    border:0;
    margin-left:8px;
    cursor:pointer;
  }
  button[type='submit']{
    background: var(--color-text-blue);
    color:#fff;
  }
  button[type='reset']{
    background:#F5F6FA;
    color:#161823;
  }
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height:36px;
    line-height:36px;
    padding:0 15px;
    margin:15px 0;
  }
  ul{
    height:420px;
    overflow: hidden;
    margin:15px 0;
  }
  li{
    background: #F5F6FA;
    height: 40px;
    line-height:40px;
    width:100%;
    border-radius:4px;
    margin:2px 0;
    padding:0 6px 0 18px;
    box-sizing:border-box;
  }
  .tit{
    width:65%;
    font-size:16px;
    font-family: 'PingFang-SC-Medium';
  }
  .num{
    width: calc(35% - 48px);
    font-size:14px;
    font-family: 'PingFang-SC-Medium';
  }
  .addbtn{
    font-size: 14px;
    background: var(--color-text-blue);
    color: #fff;
    width: 48px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius:4px;
    margin-top:6px;
    margin-left:5px;
  }
  .picked .addbtn{
    background: rgba(48, 97, 242, .2);
  }
  .flex-b-c>span{
    width:100px;
    height:28px;
    line-height:28px;
    background: var(--color-text-blue);
    color:#fff;
    font-size: 18px;
    font-family: 'PingFang-SC-Bold';
    text-align: center;
    border-radius:4px;
    cursor: default;
  }

  /* 下 */
  .foot_msg button{
    height:40px;
    line-height:40px;
    font-size:16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius:4px;
    border:none;
    width:80px;
    float: right;
    margin: calc((4.1vw - 40px) / 2) 0;
  }
  /* 弹出框 */
  ol{
    margin:15px 0;
  }

  .cont li.picked .addbtn{
    background: var(--color-text-blue);
  }
  .blue.hand{
    border:0;
    background:none;
  }
  .btn.cancle{
    background:transparent;
    color:#9D9FA6;
    font-size:16px;
  }
  .btn{
    background:var(--color-text-blue);
    color:#fff;
    width:100px;
    height:36px;
    line-height:36px;
    text-align: center;
    border-radius:4px;
    display: inline-block;
  }
  .chooseBtn{
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius: 4px;
    border: none;
    width: 160px;
    /* margin: calc((4.1vw - 40px) / 2) 0; */
  }
  /*******************************************************/
  :deep(.el-checkbox__label) {
    font-size: 15px !important;
    color: #0a84ff;
  }
  /* 背景透明 */
  :deep(.el-checkbox__inner) {
    background: transparent;
    border: 1px solid #0a84ff;
  }
  /* 选中样式 */
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
    background: #0a84ff;
    border: 1px solid #0a84ff;
  }
  /* 大小 */
  :deep(.el-checkbox__inner) {
    width: 16px !important;
    height: 16px !important;
  }
  /* 对勾样式 */
  :deep(.el-checkbox__inner::after) {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    left: 5px;
    top: 2px;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
    transform: rotate(50deg) scaleY(1.3);
  }
  /*******************************************************/
  /* uploader上传图片 样式重置 */
  .avatar-uploader :deep(.el-upload) {
    background:#fff;
    border: 1px dashed #CFD2E6;
    width:7.29vw;
    height:4.58vw;
    margin-right:15px;
    margin-bottom:10px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    flex-direction: column;
  }
  .avatar-uploader :deep(.el-upload):hover {
    border-color: #409EFF;
  }
  .icon-xiangji {
    color: var(--color-text-blue);
    font-size:30px;
  }
  .avatar {
    width: 7.29vw;
    height: 4.58vw;
    display: block;
  }
  /* 轮播图 样式重置 */
  :deep(.el-carousel__item) {
    display: flex;
  }
  :deep(.el-carousel__container){
    width:88.5%;
    margin:0 auto;
  }
  :deep(.el-carousel__arrow--left){
    left: -6%;
  }
  :deep(.el-carousel__arrow--right){
    right: -6%;
  }
  img{
    width: calc(100% / 8 - 10px);
    height:auto;
    margin-left: 10px;
  }
  :deep(.el-carousel__indicators--outside button){
    background: var(--color-text-blue);
    width: 8px;
  }
</style>
