<!-- 多级联动 -->
<template>
    <div style="float: left; margin-bottom: 10px;">
        <el-select @change="initAreas(1)" value-key="'id'"  v-model="areaData.first_id" placeholder="请选择" class="province" style="width: 145px;">
            <el-option
                v-for="item in areaData.firstData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
        </el-select>
        <el-select v-if="length>=2" @change="initAreas(2)"  v-model="areaData.second_id" placeholder="请选择" class="province" style="width: 145px;margin-left:10px;">
            <el-option
                v-for="item in areaData.secondData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
        </el-select>
        <el-select v-if="length>=3" @change="initAreas(3)"  v-model="areaData.third_id" placeholder="请选择" class="province" style="width: 145px;margin-left:10px;">
            <el-option
                v-for="item in areaData.thirdData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
        </el-select>
        <el-select v-if="length>=4" @change="initAreas(4)"  v-model="areaData.fourth_id" placeholder="请选择" class="province" style="width: 145px;margin-left:10px;">
            <el-option
                v-for="item in areaData.fourthData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
        </el-select>
    </div>
</template>

<script>
// import { watch } from 'fs'
import { reactive , getCurrentInstance,watch} from 'vue'
import { useRoute,useRouter } from 'vue-router'

export default {
  name: 'Areas',
  props:{
    length:{
        type:Number,
        default:3
    },
    first_id:{
      type: Number,
      default:0
    },
    second_id:{
      type: Number,
      default:0
    },
    third_id:{
      type: Number,
      default:0
    },
    fourth_id:{
      type: Number,
      default:0
    },
    flag:{
      type:String,
      default:'normal'
    },
    type:{
        type:String,
        default:'areas'
    }
  },

  setup(props,context){
    const route = useRoute()// eslint-disable-line no-unused-vars
    const router = useRouter() // eslint-disable-line no-unused-vars
    // let {that} = getCurrentInstance()
    let axios = getCurrentInstance().appContext.config.globalProperties;
    let length = props.length;
    let type = props.type;
    let flag = props.flag;
    let areaData= reactive({
        //第一级
        firstData:[
            {id:0,name:'请选择'}
        ],
        first_id:0,
        //第二级
        secondData:[
            {id:0,name:'请选择'}
        ],
        second_id:0,
        //第三级
        thirdData:[
            {id:0,name:'请选择'}
        ],
        third_id:0,
        //第四级
        fourthData:[
            {id:0,name:'请选择'}
        ],
        fourth_id:0,
    });


    function initAreas(level=0,init=false){

      if(level>=length){
        context.emit("changeEvent", [areaData.first_id,areaData.second_id,areaData.third_id,areaData.fourth_id],flag)
        return false;
      }
      let id = 0;
      let pid = 0;
      if(level==0){
        id = areaData.first_id;
        pid = 0;
      }else if(level==1){
        id = areaData.second_id;
        pid = areaData.first_id;
      }else if(level==2){
        id = areaData.third_id;
        pid = areaData.second_id;
      }else if(level==3){
        id = areaData.fourth_id;
        pid = areaData.third_id;
      }

      axios.$post('index/get_select', {
        type:type,
        id:id,
        level:level,
        pid:pid
      }).then(function (response) {
        let newList = response;
        newList.splice(0,0,{id:0,name:'请选择',pid:0})
        if(level==0){
            areaData.firstData = newList;
            if(init==false){
              areaData.second_id = 0;
              areaData.third_id = 0;
            }
        }
        if(level==1){
          areaData.secondData = newList;
          if(init==false){
            areaData.second_id = 0;
            areaData.third_id = 0;
          }
        }
        if(level==2){
          areaData.thirdData = newList;
          if(init==false){
            areaData.third_id = 0;
            areaData.fourth_id = 0;
          }
        }
        if(level==3){
           areaData.fourthData = newList;
        }

        context.emit("changeEvent", [areaData.first_id,areaData.second_id,areaData.third_id,areaData.fourth_id],flag)

      }).catch(function (error) {
        console.log(error);
      });
    }
    // initAreas();
    watch(()=>props.first_id,(n,old)=>{
      if(old=='undefined')old = 0;
      // console.log('first_id份由'+old+'变成了'+n)
      if(n!=old){
        areaData.first_id = Number(n)
        initAreas(0,true)
      }
    },{immediate:true,deep:true}
    )
    watch(()=>props.second_id,(n,old)=>{
      if(old=='undefined')old = 0;
      // console.log('second_id份由'+old+'变成了'+n)
      if(n!=old){
        areaData.first_id = Number(props.first_id)
        areaData.second_id = Number(n)
        initAreas(1,true)
      }
    },{immediate:true,deep:true}
    )
    watch(()=>props.third_id,(n,old)=>{
      if(old=='undefined')old = 0;
      // console.log('third_id份由'+old+'变成了'+n)
      if(n!=old){
        areaData.second_id = Number(props.second_id)
        areaData.third_id = Number(n)
        initAreas(2,true)
      }
    },{immediate:true,deep:true}
    )
    watch(()=>props.fourth_id,(n,old)=>{
      if(old=='undefined')old = 0;
      // console.log('fourth_id份由'+old+'变成了'+n)
      if(n!=old){
        areaData.fourth_id = Number(n)
        initAreas(3,true)
      }
    },{immediate:true,deep:true}
    )
    return {
        areaData,initAreas
    };
  },
}

</script>

<style scoped>
  .head{
    height:60px;
    line-height:60px;
    background:#fff;
    box-shadow:0 1px 1px 0 rgba(22,24,35,.1);
  }
</style>
