<!-- 企业信息 -->
<template>
  <el-form ref="formform" :model="form" label-width="auto">
    <RightSide>
      <template v-slot:tit>{{template_name}} </template>
<!--      <template v-slot:tit_right>
        <div class="flex_1 flex&#45;&#45;c">
          进度 <el-progress class="ml-15" :percentage="percentage" :stroke-width="16" define-back-color="#F5F6FA"></el-progress>
        </div>
      </template>-->
      <template v-slot:write_msg>
        <div class="prudence red f14"><i class="iconfont icon-zhuyi blue mr-10"></i>请谨慎填写，保存后不可更改!</div>
        <!-- 第一页 -->
        <div v-for="(item,index) in list" :key="index">
          <!-- 文本框 -->
          <el-form-item v-if="item.type==0" :label="item.title">
            <textarea class="bg_fff p10" v-model="item.value" :placeholder="'请输入'+item.title"></textarea>
            <div class="f14 red" v-if="item.remark">注：{{item.remark}}</div>
          </el-form-item>
          <!-- 输入框 -->
          <el-form-item v-if="item.type==1" :label="item.title">
            <el-input v-model="item.value" :placeholder="'请输入'+item.title" style="width:300px;"></el-input>
            <div class="f14 red" v-if="item.remark">注：{{item.remark}}</div>
          </el-form-item>
          <!-- 单选 -->
          <el-form-item v-if="item.type==2" :label="item.title">
            <el-radio-group v-model="item.value">
              <el-radio v-for="(it,k) in item.list" :key="k" :label="it.title" v-model="it.check">{{it.title}}</el-radio>
            </el-radio-group>
            <div class="f14 red" v-if="item.remark" style="width: 100%;">注：{{item.remark}}</div>
          </el-form-item>
          <!-- 复选 -->
          <el-form-item v-if="item.type==3" :label="item.title">
            <el-checkbox-group v-model="item.options">
              <el-checkbox v-for="(it,k) in item.list" :key="k" :label="it.title">{{it.title}}</el-checkbox>
            </el-checkbox-group>
            <div class="f14 red" v-if="item.remark" style="width: 100%;">注：{{item.remark}}</div>
          </el-form-item>

          <!-- 文件上传 -->
          <el-form-item v-if="item.type==5" :label="item.title" :limit="9">
            <div class="fill bg_56a p10" style="width: 100%;">
              <div class="flex--c">
                <el-upload class="avatar-uploader"
                  :action="action"
                  :headers="getHeader()"
                  :show-file-list="false"
                  :multiple="item.config.multiple"
                  :on-success="handleAvatarSuccess"
                  :data="{id:item.id,ptype:'temptags'}"
                  >
                  <img v-if="item.vaue" :src="item.vaue" class="avatar">
                  <div v-else class="text_center">
                    <i class="blue icon-xiangji avatar-uploader-icon"></i>
                    <div class="blue f14">上传{{item.title}}</div>
                  </div>
                </el-upload>
                <span class="color_9" style="width: 100%;">{{item.remark}}</span>
              </div>
              <el-carousel v-if="item.list.length>0"  indicator-position="outside" arrow="always" trigger="click" height="5.2vw" :autoplay=false>
                <el-carousel-item>
                  <div v-for="(it,k) in item.list" :key="k" style="width: calc(100% / 8 - 10px); height:auto;margin-left: 10px;position: relative;">
                    <!-- <img :src="site+it"  @click="previewImg(site+it)" style="width:100%;height: 100%;margin-left: 0;" > -->
                    <el-image
                      v-if="Api.get_file_suff(it)=='pdf'"
                      style="width: 100%; height:100%;margin-left: 10px"
                      @click="previewImg(site+it)"
                      :src="site+'/static/index/img/pdf200.jpg'"
                      :lazy="true" >
                    </el-image>
                    <el-image
                      v-else
                      style="width: 100%; height:100%;margin-left: 10px"
                      @click="previewImg(site+it)"
                      :src="site+it"
                      :lazy="true" >
                    </el-image>
                    <el-icon @click="deleteImg(index,k)" style="vertical-align: middle;color: red;position: absolute;top: -2px;right: -8px;cursor: pointer;"><circle-close/></el-icon>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-form-item>

          <!-- 时间选择 -->
          <el-form-item v-if="item.type==7" :label="item.title">
            <el-date-picker
              v-model="item.value"
              type="date"
              format="YYYY 年 MM 月 DD 日"
              value-format="YYYY-MM-DD"
              placeholder="选择日期">
            </el-date-picker>
            <div class="f14 red" v-if="item.remark">注：{{item.remark}}</div>
          </el-form-item>
<!--          <NewUpload :list="list" :item="item" :index="index" @changeItemList="changeItemList" ></NewUpload>-->
        </div>
      </template>
      <template v-slot:foot_msg>
        <button class="prebtn fl" :class="{'hide':page<=1}" @click.prevent="preStep">上一步</button>
        <button class="fr" v-if="page===totalPage" @click.prevent="complate">完成</button>
        <button class="fr" v-else @click.prevent="nextStep">下一步</button>
      </template>
    </RightSide>
  </el-form>
</template>

<script>
import {ref,toRefs,reactive,getCurrentInstance,watch} from 'vue'
import { useRoute, useRouter } from 'vue-router'// eslint-disable-next-line no-unused-vars
import RightSide from '@/components/content/home/RightSide.vue'
import { layer } from '@layui/layer-vue'
//import NewUpload from '@/components/NewUpload.vue'
export default {
  name: 'Information',
  components: {
    RightSide,//NewUpload
},
  setup(props,context){
    var  route = useRoute()// eslint-disable-line no-unused-vars
    var router = useRouter()// eslint-disable-line no-unused-vars
    let template_id = route.params.template_id||0
    let page = ref(1);
    let pageSize = ref(5);
    let totalPage = ref(0);
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi

    const defaultData = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      allList:[],
      list:[],
      percentage:0,
      headers:{},
      template_name:'模板信息'
    });
    const getHeader = ()=>{
      let headers = Api.getHeader();
      let header = {}
      header.USERKEY = headers.userKey;
      header.USERTOKEN = headers.token;
      return header
    }
    //获取模板须填写项
    const getTagsList = ()=>{
      proxy.$post('/template/getFormInfo',{
        template_id:template_id,
        page:page.value,
      }).then((response)=>{
        defaultData.percentage = response.percent;
        var allList = response.list
        allList.forEach((v,k)=>{
          if(v.type==3){
            var options = []
            if(!(!v.value||v.value=='undefinde'||v.value==null||v.value=='null')){
              options = v.value.split(',')
            }
            allList[k].options = options
          }
        })
        defaultData.allList = allList
        let nowList = [];
        allList.forEach( function(v, k) {
          if(k>=(page.value-1)*pageSize.value&&k<page.value*pageSize.value)nowList.push(v);
        });
        defaultData.list = nowList;
        defaultData.template_name = response.template_name
        totalPage.value = Math.ceil(response.list.length/pageSize.value);
      }).catch(()=>{

      });
    }
    // getTagsList();
    //获取当前页需填写的项
    const getCurrentForm = (p)=>{
      let allList = defaultData.allList;
      let nowList = [];
      // console.log(p,' ',(p-1)*pageSize.value,'',p*pageSize.value)
      allList.forEach( function(v, k) {
        if(k>=(p-1)*pageSize.value&&k<p*pageSize.value)nowList.push(v);
      });
      defaultData.list = nowList;
      // console.log(nowList)
    }
    //保存当前页
    const saveCurrentPage = (list)=>{
      let formData = {};
      list.forEach( function(v) {
        if(v.type==5){
          formData[v.code] = v.list.join(',');
        }else if(v.type==3){
          formData[v.code] = v.options.join(',');
        }else{
          formData[v.code] = v.value;
        }

      });
      proxy.$post('/template/saveForms',{
        template_id:template_id,
        forms:formData,
      }).then(()=>{
          resetPercent();
          Api.msg('保存成功！',0,'right');
      }).catch(()=>{

      });
    }
    //下一页
    const nextStep = ()=>{
      saveCurrentPage(defaultData.list);
      // console.log(page.value,'|',totalPage.value)
      if(page.value>=totalPage.value){
        return false;
      }else{
        page.value += 1;
        getCurrentForm(page.value);
      }
    }
    //上一页
    const preStep = ()=>{
      if(page.value>1){
        page.value -= 1
      }else{
        page.value = 1
      }
      getCurrentForm(page.value);
    }
    //保存
    const complate = ()=>{
      let list = defaultData.list;
      saveCurrentPage(list)
    }
    //上传成功
    function handleAvatarSuccess(response){
      if(response.code==1){
        Api.msg(response.msg)
      }else{
        var allList = defaultData.allList;
        var id = response.data.id;
        allList.forEach( function(v, k) {
          if(v.id==id){
            allList[k]['list'].push(response.data.src)
          }
        });
        defaultData.allList = allList;
      }

    }
    //更新进度
    const resetPercent = ()=>{
      let totalNum = 0;
      let complete = 0;
      defaultData.allList.forEach(v=>{
        totalNum++;
        if(v.value){
          complete++
        }
      })
      let percent = parseInt((complete/totalNum)*100)
      defaultData.percentage = percent
      let data = {}
      data.template_id = template_id;
      data.percent = percent;
      context.emit("updatePercent", data)
    }
    //图片预览
    const previewImg = (url)=>{
      layer.open({
          type:2,
          content:url,
          area:['60%','80%'],
      })
      // if(Api.get_file_suff(url)=='pdf'){
      //   layer.open({
      //     type:2,
      //     content:url,
      //     area:['60%','80%'],
      //   })
      // }else{
      //   proxy.$messageBox({
      //     message:'<img src="'+url+'"/><style>.el-message-box{max-width: inherit;width: auto;}</style>',
      //     dangerouslyUseHTMLString:true,
      //     showClose:true,
      //     showConfirmButton:false,
      //   })
      // }

    }
    //删除图片文件
    const deleteImg = (index,k)=>{
      // let allList = defaultData.allList
      let allList = defaultData.list;
      var row = allList[index];
      var path = row.list[k];
      var template_id = row.template_id;
      var code = row.code;
      proxy.$post('index/delImg',{
        path:path,template_id:template_id,code:code
      }).then(res=>{
        if(res.code==0){
          if(row.list.length==1){
            row.list = []
          }else{
            row.list.splice(k, 1)
          }
          allList[index] = row
          defaultData.list = allList
        }
      })
    }
    //========================================
    function changeItemList(list){
      defaultData.list = list
    }
    //========================================
    //监听路由变化
    watch(()=>router.currentRoute.value.path,(toPath)=>{
      if(toPath=='/home'){
        template_id = 0;
      }else if(toPath.indexOf('/home/information')!==-1){
        template_id = route.params.template_id||0
        page.value = 1
        getTagsList()
      }
    },{immediate:true,deep:true});
    return{
      ...toRefs(defaultData),page,totalPage,
      nextStep, preStep, complate,resetPercent,previewImg,deleteImg,getHeader,
      handleAvatarSuccess,Api,changeItemList
    }
  }
}

</script>

<style scoped>

/* 上 */
  :deep(.el-progress){
    width: calc(100% - 4em);
  }
  :deep(.flex--c .el-progress-bar__inner){
    background: linear-gradient(90deg, #3061F2 0%, #AAA0FA 100%);
  }
  .el-upload-list--text{display: none;}

/* 中间部分 */
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height:36px;
    line-height:36px;
    padding:0 15px;
    margin:15px 0;
  }
  .write_msg span.f14{
    line-height:32px;
  }
  .fill{
    /* width: calc(100% - 4em - 15px); */
    font-size: 14px;
    box-sizing:border-box;
    line-height: 24px;
  }
  :deep(.el-form-item__content){
    width: calc(100% - 80px);
  }
  /* uploader上传图片 样式重置 */
  .avatar-uploader :deep(.el-upload) {
    background:#fff;
    border: 1px dashed #CFD2E6;
    width:7.29vw;
    height:4.58vw;
    margin-right:15px;
    margin-bottom:10px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    flex-direction: column;
  }
  .avatar-uploader :deep(.el-upload):hover {
    border-color: #409EFF;
  }
  .icon-xiangji {
    color: var(--color-text-blue);
    font-size:30px;
  }
  .avatar {
    width: 7.29vw;
    height: 4.58vw;
    display: block;
  }
  /* 轮播图 样式重置 */
  :deep(.el-carousel__item) {
    display: flex;
  }
  :deep(.el-carousel__container){
    width:88.5%;
    margin:0 auto;
  }
  :deep(.el-carousel__arrow--left){
    left: -6%;
  }
  :deep(.el-carousel__arrow--right){
    right: -6%;
  }
  img{
    width: calc(100% / 8 - 10px);
    height:auto;
    margin-left: 10px;
  }
  :deep(.el-carousel__indicators--outside button){
    background: var(--color-text-blue);
    width: 8px;
  }
  /* switch样式重置 */
  :deep(.el-switch__label--left.is-active){
    color: var(--color-text-white);
    position:absolute;
    left:20px;
    z-index: 2;
    display: block;
  }
  :deep(.el-switch__label--left),
  :deep(.el-switch__label--left.is-active+span+span){
    display: none;
  }
  :deep(.el-switch__label--right.is-active){
    color: var(--color-text-white);
    position:absolute;
    left:-4px;
    z-index: 2;
  }
  textarea{
    width:100%;
    background:#fff;
    border:1px solid #CFD2E6;
    padding:6px 15px;
    border-radius: 4px;
    box-sizing:border-box;
  }

/* 下 */
  button{
    height:34px;
    line-height:34px;
    font-size:16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #AAA0FA 100%);
    border-radius:4px;
    border:none;
    padding:0 60px;
    margin: calc((4.1vw - 34px) / 2) 0;
  }
  .prebtn{
    background: #f3f4fa;
    color: var(--color-text-blue);
  }

</style>
