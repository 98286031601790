import {createRouter, createWebHistory} from 'vue-router'
import {Login, GetPwd, ResetPwd} from '@/views/login/index.js'  //Register,
import Change from '@/views/change/Change'
import Home from '@/views/home/Home'
import MyListing from '@/views/listing/MyListing'
import Api from '@/store/commonApi'
import {
    Information,
    Department,
    ExternalList,
    BaseInfo,
    Address,
    Flow,
    Qualification,
    ViewProject,
    EquipmentSchedule,
    Customer,
    Supplier
}from '@/views/home/childComps/index.js'
import demo from '@/views/login/demo.vue'

const routerHistory = createWebHistory()

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/login',
        component: Login,
        meta: {
            title: '认证助手-登录',
            isLogin: false
        }
    },
    {
        path: '/login/getpwd',
        component: GetPwd,
        meta: {
            title: '认证助手-找回密码',
            isLogin: false
        }
    },
    {
        path: '/login/resetpwd',
        component: ResetPwd,
        meta: {
            title: '认证助手-重置密码',
            isLogin: false
        }
    },
    {
        path: '/demo',
        component: demo
    },
    {
        path: '/myListing',
        component: MyListing
    },
    /*{
        path: '/register',
        component: Register,
        meta: {
            title: '认证助手-注册',
            isLogin: false
        }
    },*/
    {
        path: '/change',
        component: Change,
    },
    {
        path: '/home',
        component: Home,
        meta: {
            title: '认证助手',
            isProject: true,
        },
        children: [
            {
                path: '',
                component: BaseInfo
            },
            {
                path: 'baseinfo',
                name: '基本信息',
                component: BaseInfo

            },
            {
                path: 'address',
                name: '企业地址',
                component: Address

            },
            {
                path: 'department',
                name: '部门人员',
                component: Department

            },
            {
                path: 'information/:template_id?',
                name: '企业信息',
                component: Information
            },
            {
                path: 'flow',
                name: '工艺流程',
                component: Flow
            },
            {
                path: 'externalList',
                name: '外来清单',
                component: ExternalList
            },
            {
                path: 'qualification',
                name: '相关资质',
                component: Qualification
            },
            {
                path: 'equipmentSchedule',
                name: '设备清单',
                component: EquipmentSchedule
            },
            {
                path: 'customer',
                name: '顾客管理',
                component: Customer
            },
            {
                path: 'supplier',
                name: '供方管理',
                component: Supplier
            },
            {
                path: 'viewProject',
                name: '文件预览',
                component: ViewProject
            },
        ]
    },
]
const router = createRouter({
    history: routerHistory,
    mode: "hash",
    base: process.env.BASE_URL,
    routes
})
// 判断登录
router.beforeEach(function (to, from, next) {
    document.title = to.meta.title ? to.meta.title : '认证助手'
    var uid = Api.checkLogin();
    var project_id = Api.sessionGet('project_id')
    if (to.meta.isLogin !== false) {
        //判断登录
        if (uid > 0) {
            if (project_id > 0) {
                next()
            } else {
                if (to.path != '/change') {
                    next('/change');
                } else {
                    next()
                }
                // next()

            }
        } else {
            next('/login')
        }
    } else {
        if (to.path == '/login' && uid > 0) {
            next('/home');
        } else {
            next();
        }
    }
});
export default router
