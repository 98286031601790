<template>
  <el-container>
    <el-aside class="f40">
      <img src="@/assets/img/logo.png" >咨询工具
    </el-aside>
    <!-- 用户登录 -->
    <el-main >
      <div class="flex-b-">
        <div>
          <div class="f48 text_left bold">欢迎登录</div>
          <div class="f16">微信扫码进行登录</div>
        </div>
      </div>
      <!-- 账号密码登录 -->
      <el-form v-if="isShow" :model="ruleForm" status-icon  ref="ruleForm" class="demo-ruleForm">
        <el-form-item >
          <i class="icon-user blue f20 mr-15"></i> <span class="f18">账号</span>
          <el-input v-model="account" @keyup.enter="login" placeholder="请输入账号" clearable autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <i class="icon-mima blue f20 mr-15"></i> <span class="f18">密码</span>
          <el-input type="password"  v-model="pwd" @keyup.enter="login" placeholder="请输入密码" clearable autocomplete="off"></el-input>
        </el-form-item>
        <div class="text_right f16 color_9 hand mt-10" @click="toGetPwd">忘记密码？</div>
        <div class="mt-100">
          <el-form-item class="color_9">
            点击登录表示您同意遵守<span class="blue hand">《证果果用户协议》</span>及<span class="blue hand">《证果果隐私权政策》</span>
          </el-form-item>
          <el-button type="primary" @click="login">登 录</el-button>
        </div>
        <div class="f14 text_center mt-10 color_9">
          <!-- 没有账户，<span class="blue hand" @click="toRegister">立即注册</span> -->
        </div>
      </el-form>
      <!-- 扫二维码 微信登录 -->
      <el-form v-else>
        <div class="text_center">
          <div class="qrcode pr text_center lose">
            <img v-if="qrcode_img_show" :src="wx_src" />
            <img v-if="img_show" src="@/assets/img/login/login_qrcode.jpg" >
            <div class="lose" v-if="lose" @click="qrcode_refresh">
              <p class="color_f f16">二维码已失效，点击刷新</p>
              <i class="icon-shuaxin"></i>
            </div>
          </div>
          <i class="icon-shouji"></i>
          <div class="f16 color_9 lh2">请使用微信扫描二维码进行登录</div>
          <div class="f14 text_center mt-10 color_9">
            <!-- 没有账户，
            <span class="blue hand" @click="toRegister">立即注册</span> -->
          </div>
        </div>
      </el-form>
    </el-main>
  </el-container>
</template>

<script >
import {ref, getCurrentInstance, onMounted, onUnmounted, reactive} from 'vue';
import { useRouter } from 'vue-router'
import { layer } from "@layui/layer-vue";
export default {
  name:'Login',

  setup(){
    // data
    const router = useRouter();
    let isShow = ref(true);
    let account = ref('');
    let pwd = ref('');
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    let lose = ref(false);
    const wx_src = ref('');
    const qrcode_img_show = ref(true);
    const img_show = ref(false);
    const defaultData = reactive({
      scene_str:'',
      is_flag:0,
      timerId:0,
      i:0
    });

    onMounted(() => {
      get_wx_qrcode();
      // 监听页面离开事件
      window.addEventListener('beforeunload', clearTimer);
    });

    // 获取二维码
    function get_wx_qrcode(){
      proxy.$post('login/get_wx_qrcode').then(function (response) {
        if (response.code==0 && response.data.ticket!=''){
          qrcode_img_show.value = true;
          img_show.value = false;
          wx_src.value = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + response.data.ticket;
          defaultData.scene_str = response.data.scene_str;
          defaultData.timerId = setInterval(getResult, 3000);
        }else {
          qrcode_img_show.value = false;
          img_show.value = true;
        }
      }).catch(function (error) {
        console.log(error);
      });
    }

    function login(){
      if( !account.value ){
        layer.msg('请输入账号');
        return false;
      }
      if( !pwd.value ){
        layer.msg('请输入密码');
        return false;
      }
      proxy.$post('login/login', {
        user_name:account.value,
        password:pwd.value,
      }).then(function (response) {
        layer.msg(response.msg,{time: 2000},function(){
          if(response.code==200){
            Api.storageSet('user_id',response.user_id,3600);
            Api.storageSet('project_id',0,3600);
            Api.sessionSet('user_id',response.user_id);
            Api.sessionSet('project_id',0);
            router.push('/change')
          }
        });
      }).catch(function (error) {
        console.log(error);
      });
      return
    }

    //获取登录状态
    function getResult() {
      proxy.$post('login/chk_login_status',{"scene_str": defaultData.scene_str}).then(function (response) {
        if(response.is_flag==1){
          clearTimer();
          Api.storageSet('user_id',response.user_id,3600);
          Api.storageSet('project_id',0,3600);
          Api.sessionSet('user_id',response.user_id);
          Api.sessionSet('project_id',0);
          router.push('/change')
        }else {
          if (response.code==1){
            clearTimer();
            Api.msgbox(response.msg, '消息提示', 'error', 1);
          }
          return false;
        }
      }).catch(function (error) {
        console.log(error);
      });
      defaultData.i++;
      if (defaultData.i > 30) {
        lose.value = true;
        clearTimer();
      }
    }
    // 刷新二维码
    function qrcode_refresh(){
      lose.value = false;
      defaultData.i = 0;
      defaultData.timerId = setInterval(getResult, 3000);
    }
    // 组件卸载时
    onUnmounted(() => {
      clearTimer();
    });

    // 清除定时器
    function clearTimer() {
      clearInterval(defaultData.timerId);
      window.removeEventListener('beforeunload', clearTimer);
    }
    return{
      get_wx_qrcode, wx_src, qrcode_img_show, img_show, getResult, defaultData, qrcode_refresh,
      account, pwd, isShow, login, lose
    }
  },
}
</script>

<style scoped>
  @import url(@/assets/css/reset.css);
  .qrcode .lose{
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(20.75vw + 2px);
    height: calc(20.75vw + 2px);
    border-radius:10px;
    transform: translate(-50%);
    background: rgba(0,0,0,.8);
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .qrcode.lose .lose{
    display: flex;
    cursor: pointer;
  }
  .lose i{
    font-size:50px;
    background: #fff;
    border-radius:50%;
    padding:4px;
    margin-top:20px;
  }
  .qrcode+i{
    display: block;
    font-size: 30px;
    color: #ECF1FA;
    margin-top:5.2vw;
  }
  .el-aside {
    background: url(@/assets/img/login/login.png) no-repeat left top/100% 100vh;
    width: 61.15vw;
    padding-left:3.4vw;
    padding-top:4.17vw;
    font-family: PingFang-SC-Bold;
  }
  .el-aside img{
    width:3.4vw;
    margin-right:12px;
  }
  .el-main {
    padding: 4.17vw 4.17vw 0 3.39vw;
    height:100vh;
  }
  .f48{
    font-family: PingFang SC;
    line-height:1.2;
  }
  .flex-b- .f16{
    color:#9D9FA6;
    margin-top:10px;
  }
  .flex-b- .f16::before{
    content:'';
    width: 3px;
    height: 14px;
    display: inline-block;
    background: #9D9FA6;
    margin-right: 8px;
    vertical-align: middle;
  }
  .flex--c span{
    background: #E5F1FE;
    border:1px solid #0077FA;
    border-radius:5px;
    display: inline-block;
    padding:5px 10px 5px 6px;
    position:relative;
  }
  .flex--c span:before{
    content:'';
    position:absolute;
    width:0;
    height:0;
    top:calc(50% - 6px);
    right:-8px;
    border-left: 8px solid #0077FA;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    z-index: 1;
  }
  .flex--c span::after{
    content:'';
    position:absolute;
    width:0;
    height:0;
    top:calc(50% - 6px);
    right:-7px;
    border-left: 8px solid #E5F1FE;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    z-index: 2;
  }
  .flex--c img{
    width: 6.25vw;
    height:6.25vw;
  }

  .el-form{
    margin-top:60px;
  }
  .el-form-item{
    margin-bottom:0;
    margin-top: 2.6vw;
  }
  i.f20{
    font-size: 20px;
  }
  .f18{
    color: #161823;
  }
  .el-input{
    height:56px;
  }
  :deep(.el-input__wrapper){
    padding-left: 2.08vw;
    font-size:18px;
    border-radius:8px;
  }
  :deep(.el-input__wrapper.is-focus){
    border:1px solid #CFD2E5;
    box-shadow: 0px 0px 10px 1px rgba(1,27,70,0.1) !important;
  }
  .el-form img{
    width:18.75vw;
    height:18.75vw;
    padding:1vw;
    border:1px solid #CFD2E5;
    border-radius:10px;
  }
  .el-form img+i{
    display: block;
    font-size: 30px;
    color: #ECF1FA;
    margin-top:5.2vw;
  }
  .f14.text_center{
    margin-top:100px;
  }
  .f14.mt-10{
    margin-top:10px;
  }
  .el-button{
    width: 100%;
    height:3.75vw;
    background:#0077fa;
    font-size: 20px;
    margin-top:10px;
  }
</style>
