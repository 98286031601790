<!-- 文件预览 -->
<template>
  <RightSide>
    <template v-slot:tit>
      打包文件预览
      <button class="addbtn" style="float: right;width: 100px; margin-top: 15px;" @click="packageProject()">打包下载</button>
      <div v-if="is_package" class="progress-bar">
        <div class="progress-bar-inner">
          <el-progress type="circle" :width="180" :percentage="downloadProgress" :status="download_status">
            <template #default="{ percentage }">
              <span class="percentage-value">{{ percentage }}%</span>
              <span class="percentage-label">打包中。。。</span>
            </template>
          </el-progress>
        </div>
      </div>
    </template>
    <template v-slot:write_msg>
      <ul v-if="list.length>0">
        <li v-for="(item,index) in list" :key="index" class="flex">
          <div class="tit line1">{{item.title}}</div>
          <div class="num line1"></div>
          <div class="addbtn" style="margin-top: 6px;" @click="viewFile(item)">预 览</div>
        </li>
      </ul>
      <el-empty v-else description="暂无文件列表！">&nbsp;</el-empty>
      <div class="flex-b-c mb-10">
        <!-- 分页 -->
        <el-pagination v-if="list.length>0" v-model:current-page="current_page"
          :page-size="pageSize" layout="prev, pager, next, jumper" :total="count">
        </el-pagination>
      </div>
      <el-dialog v-model="isShow" title="预览文件" top="10vh" width="850" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="false">
        <div class="dialog-content">
        <div v-for="(item, index) in content_list" :key="index">
          <div style="padding: 30px;" v-html="item.content"></div>
          <div v-if="item.file_type=='project_manual' && index>7" class="close_btn" @click="isShow=false;">关 闭</div>
          <div v-if="item.file_type=='project_manual' && index>7" class="addbtn" @click="innerVisible=true;record_id=item.id;manualHtml=item.content;">修 改</div>
        </div>
        </div>
        <el-dialog
            v-model="innerVisible"
            width="800"
            height="500"
            title="内容修改"
            :close-on-click-modal="false" :close-on-press-escape="false"
            :append-to-body="false"
        >
          <div style="border: 1px solid #ccc">
            <Editor
                api-key="uahg8l3f2b52kizlkonoxwoduijvjy7liz06xgq6m2uurqrh"
                v-model="manualHtml"
                :init="init"
            />
          </div>
          <template #footer>
            <el-checkbox v-model="is_change_recode" label="是否生成修改记录" size="large" />
            <el-button type="primary" @click="saveChange" style="margin-left: 20px;">保 存</el-button>
            <el-button @click="innerVisible=false" plain>取 消</el-button>
          </template>
        </el-dialog>
      </el-dialog>
    </template>
    <template v-slot:foot_msg>
      <span style="display:flex;align-items: center;height: 4.1vw;color:red;">选择相应文件预览或修改</span>
    </template>
  </RightSide>
</template>

<script>
import {ref, reactive, toRefs, onMounted, getCurrentInstance, nextTick} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
//import {hideLoading, showLoading} from "@/utils/loading";
// 引入编辑器
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'ViewProject',
  components: {
    RightSide,
    Editor,
  },
  props: {},
  setup (props,context) {
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    let _this = reactive({
      site: Api.WEB_SITE,
      list:[],
      content_list:[],
      keyword:'',
      pageSize:10,
      count:0,
      current_page:1,
      is_package:false,
      downloadProgress:0,
      download_status:'',
    });
    let isShow = ref(false);  // 预览弹窗
    let innerVisible = ref(false);  // 修改内容弹窗
    let is_change_recode = ref(false);  // 是否生成修改记录标识
    let record_id = ref(0);  // 修改记录id

    // 初始化富文本编辑器
    const init = {
      //language_url: '/tinymce/langs/zh_CN.js', // 中文化 默认为英文
      //base_url: '/tinymce', // 基础路径
      language: 'zh_CN', // 设置富文本编辑器语言
      license_key: 'gpl', // 注册key
      toolbar: 'undo redo fontfamily fontsize forecolor backcolor bold italic underline strikethrough alignleft aligncenter alignright removeformat',
      font_size_formats: '9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt', //文字大小
      font_family_formats: '宋体=SimSun;黑体=SimHei;仿宋=FangSong;楷体=KaiTi;隶书=LiSu;幼圆=YouYuan;微软雅黑=Microsoft YaHei;Arial;Times New Roman;Verdana;', // 工具栏自定义字体选项
      height: 500,  // 设置高度
      menubar: false, // 设置富文本编辑器菜单, 默认true
      branding: false, // 关闭底部官网提示 默认true
      statusbar: true, // 显示底部状态栏 默认true
      readonly: false, // 设置只读属性 默认 false
      resize: false, // 调节编辑器大小 默认 true
    };
    let manualHtml = ref('');

    onMounted(()=>{
      getListing();
    });
    //获取列表
    const getListing = ()=>{
      //var load = layer.load(0,{offset:['40%','65%']});
      proxy.$post("Project/previewProject",{
        page:_this.current_page,
      }).then((res)=>{
        let list = res.data
        _this.list = list;
        _this.pageSize = res.pageSize;
        _this.count = res.count;
        _this.current_page = res.current_page
        //layer.close(load);
      });
    }

    //预览
    const viewFile = (item)=>{
      proxy.$post("Project/projectFile",{
        file_type:item.file_type,
      }).then((res)=>{
        if(res.code == 0){
          isShow.value = true;
          _this.content_list = res.data;
        }else{
          Api.msg(res.msg);
        }
      });
    }

    // 保存
    const saveChange = () => {
      proxy.$post("Project/saveChangeRecord",{
        record_id: record_id.value,
        change_info: manualHtml.value,
        is_change_recode: is_change_recode.value?1:0,
      }).then((res)=>{
        if(res.code == 0){
          Api.msg(res.msg);
          innerVisible.value = false;
        }else {
          Api.msg(res.msg);
        }
      })
    }

    // 打包
    const packageProject = () => {
      //showLoading();
      _this.is_package = true;
      proxy.$post_progress('project/packageProject',
          {},callback
      ).then(res => {
        if (res.code == 0) {
          ///hideLoading();
          _this.downloadProgress = 100;
          _this.download_status = 'success';
          setTimeout(()=> {
            _this.is_package = false;
            _this.downloadProgress = 0;
            _this.download_status = '';
            window.location.href = Api.WEB_SITE + res.data;
          } , 1000);
        } else {
          _this.is_package = false;
          Api.msg(res.msg);
          return false;
        }
      })
    }
    const callback = (progressEvent) => {
        nextTick(() => {//下载进度计算，这里使用Vuejs的nextTick函数
          const intervalId = setInterval(() => {
            if (_this.downloadProgress >= 100) {
              clearInterval(intervalId);
            } else {
              _this.downloadProgress += 5; // 假设每次增加5%
            }
          }, 3000); // 每秒检查一次进度
          console.log(progressEvent);
          //_this.downloadProgress_ = parseInt(100 * (progressEvent.loaded / progressEvent.total));
          /*if (_this.downloadProgress == 100) {
            _this.download_status = 'success';
          }*/
        });
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -3;
      context.emit("updatePercent", data)
    }

    return {
      ...toRefs(_this), isShow, innerVisible,is_change_recode,record_id,
      resetPercent, packageProject, viewFile, getListing,saveChange,
      manualHtml, init,
    }
  }
}
</script>

<style scoped>
  .dialog-content {
    height: 750px; /* 设置一个固定高度 */
    overflow: hidden; /* 超出时隐藏 */
    overflow-y: auto; /* 超出时显示滚动条 */
  }
  button{
    width:64px;
    height:32px;
    line-height:32px;
    text-align: center;
    border-radius:4px;
    font-size:16px;
    border:1px solid #9D9FA6;
    margin-left:8px;
    cursor:pointer;
  }
  button[type='submit']{
    background: var(--color-text-blue);
    color:#fff;
  }
  button[type='reset']{
    background:#F5F6FA;
    color:#161823;
  }
  ul{
    height:420px;
    overflow: hidden;
    margin:15px 0;
  }
  li{
    background: #F5F6FA;
    height: 40px;
    line-height:40px;
    width:100%;
    border-radius:4px;
    margin:2px 0;
    padding:0 6px 0 18px;
    box-sizing:border-box;
  }
  .tit{
    width:65%;
    font-size:16px;
    font-family: 'PingFang-SC-Medium';
  }
  .num{
    width: calc(35% - 48px);
    font-size:14px;
    font-family: 'PingFang-SC-Medium';
  }
  .addbtn{
    font-size: 14px;
    background: var(--color-text-blue);
    color: #fff;
    width: 48px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius:4px;
    /*margin-top:6px;*/
    margin-left:5px;
    cursor:pointer;
    float: right;
  }
  .close_btn{
    font-size: 14px;
    background: transparent;  /*var(--color-text-lightgrey);*/
    color: #1c1b22;
    width: 48px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    border-radius:4px;
    border:1px solid #9D9FA6;
    /*margin-top:6px;*/
    margin-left:15px;
    margin-right:15px;
    cursor:pointer;
    float: right;
  }
  .picked .addbtn{
    background: rgba(48, 97, 242, .2);
  }
  .flex-b-c>span{
    width:100px;
    height:28px;
    line-height:28px;
    background: var(--color-text-blue);
    color:#fff;
    font-size: 18px;
    font-family: 'PingFang-SC-Bold';
    text-align: center;
    border-radius:4px;
    cursor: default;
  }

/* 下 */
.foot_msg button{
    height:40px;
    line-height:40px;
    font-size:16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius:4px;
    border:none;
    width:200px;
    margin: calc((4.1vw - 40px) / 2) 0;
  }
  /* 弹出框 */
  ol{
    margin:15px 0;
  }

  .cont li.picked .addbtn{
    background: var(--color-text-blue);
  }
  .blue.hand{
    border:0;
    background:none;
  }
  .btn.cancle{
    background:transparent;
    color:#9D9FA6;
    font-size:16px;
  }
  .btn{
    background:var(--color-text-blue);
    color:#fff;
    width:100px;
    height:36px;
    line-height:36px;
    text-align: center;
    border-radius:4px;
    display: inline-block;
  }
  .chooseBtn{
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius: 4px;
    border: none;
    width: 160px;
    /* margin: calc((4.1vw - 40px) / 2) 0; */
  }
  /********** 打包进度条样式 **********/
  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    z-index: 1000;
  }
  .progress-bar-inner {
    position: relative;
    top: 30%;
    left: 50%;
    width: 300px;
    height: 200px;
    background-color: #ffffff;
    color: #000;
    font-weight: bold;
    border-radius: 5px;
    padding-top: 30px;
    z-index: 1001;
  }
  .percentage-value {
    display: block;
    margin-top: 10px;
    font-size: 28px;
  }
  .percentage-label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
  }
</style>
