<!-- 外来清单 -->
<template>
    <div style="padding: 20px;">
       <el-table
        :data="list"
        stripe
        style="width: 100%">
        <el-table-column
          prop="name"
          label="姓名"
          >
        </el-table-column>
        <el-table-column
          prop="number"
          label="部门"
          >
        </el-table-column>

        <el-table-column
          label="操作"
          key="slot"
          align="left"
          width="180"
          >
          <template #default="scope">
            <el-button @click="handleEdit(scope.row)" type="text" style="margin-left: 4px;" size="small">编辑</el-button>
            <el-button v-if="scope.row.is_staff==1" @click="handleDelete(scope.row)" type="text" style="color: red;margin-left: 4px;" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-b-c mb-10">
        <!-- <span @click="chooesedList">所选清单</span> -->
        <!-- 分页 -->
        <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-model:current-page="currentPage"
          :page-size="10" layout="prev, pager, next, jumper" :total="list.length">
        </el-pagination> -->
        <!-- <el-pagination layout="prev, pager, next" :page-size="8" :total="list.length" ></el-pagination> -->
      </div>
      <div class="layer-bottom">
          <button class="blue hand" @click="deleAll">全部删除</button>
          <div style="position: absolute;right: 30px;">
              <span class="btn cancle" @click="closeLayer">取消</span>
              <span class="btn" @click="create">生成清单</span>
          </div>
      </div>
    </div>
</template>

<script>
import { reactive,toRefs, onMounted, getCurrentInstance} from 'vue'
//import { layer } from "@layui/layer-vue";

export default {
  name: 'ExternalList',
  components: {
  },
  props: {},
  setup () {
    const {proxy} = getCurrentInstance()
    let data = reactive({
      list:[],
    });
    onMounted(()=>{
      getMyListing();
    })
     //已加入列表中的清单
    const getMyListing = ()=>{
      proxy.$post('listing/init_listing',{}).then((response)=>{
        data.list = response.list;
      });
    }
    //关闭弹窗
    const closeLayer = ()=>{
      //console.log(layer)
      var index = parent.layer.getFrameIndex(window.name)
      parent.layer.close(index);
    }

    return {
      ...toRefs(data),closeLayer
    }
  }
}
</script>

<style scoped>
  .fill{display: inline-block;}
  .layer-bottom{
    width: 96vw;
    height: 55px;
    border-top: 1px solid #ddd;
    position: fixed;
    bottom: 0;
    background: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  /*layer 弹窗*/
  .layui-layer-iframe{
    border-radius: 20px;
  }
/* 中间部分 */
  form{
    margin-top:24px;
    margin-bottom:24px;
  }
  form>.flex+div{
    margin-top:16px;
  }
  .input{
    background:#F5F6FA;
    border-radius:4px;
    height:36px;
    line-height:36px;
  }
  .input+div{
    margin-left:12px;
    background: rgba(48, 97, 242, .1);
    color: var(--color-text-blue);
    font-size: 16px;
    width: 5em;
    cursor: pointer;
  }
  .input+div i{
    display: inline-block;
    transition: transform .3s;
  }
  .trans180{
    transform: rotate(180deg);
    transition: transform .3s;
  }
  .input .el-select{
    width:100px;
    height:24px;
    line-height:24px;
    border-right:1px solid #9D9FA6;
    margin-top:-5px;
    border-left-color:transparent !important;
    border-top-color:transparent !important;
    border-bottom-color:transparent !important;
    --el-select-border-color-hover: transparent;
  }
  .input :deep(.el-input){
    line-height:24px;
    height:24px;
    border-color: transparent;
  }
  .input :deep(.el-input__wrapper){
    background:transparent;
    box-shadow:none;
  }
  .input :deep(.el-select .el-input.is-focus .el-input__wrapper),
  .input :deep(.el-select .el-input__wrapper.is-focus){
    box-shadow:none !important;
  }
  .input input{
    height:36px;
    line-height:36px;
    border:none;
    background:transparent;
    padding-left:1.19vw;
  }
  .input i{
    width:50px;
    height:36px;
    font-size:22px;
    background: var(--color-text-blue);
    color:#fff;
    text-align: center;
    display: inline-block;
    border-radius:0 4px 4px 0;
    cursor:pointer;
  }
  button{
    width:64px;
    height:32px;
    line-height:32px;
    text-align: center;
    border-radius:4px;
    font-size:16px;
    border:0;
    margin-left:8px;
    cursor:pointer;
  }
  button[type='submit']{
    background: var(--color-text-blue);
    color:#fff;
  }
  button[type='reset']{
    background:#F5F6FA;
    color:#161823;
  }
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height:36px;
    line-height:36px;
    padding:0 15px;
    margin:15px 0;
  }
  ul{
    height:420px;
    overflow: hidden;
    margin:15px 0;
  }
  li{
    background: #F5F6FA;
    height: 40px;
    line-height:40px;
    width:100%;
    border-radius:4px;
    margin:2px 0;
    padding:0 6px 0 18px;
    box-sizing:border-box;
  }
  .tit{
    width:65%;
    font-size:16px;
    font-family: 'PingFang-SC-Medium';
  }
  .num{
    width: calc(35% - 48px);
    font-size:14px;
    font-family: 'PingFang-SC-Medium';
  }
  .addbtn{
    background: var(--color-text-blue);
    color: #fff;
    width: 48px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius:4px;
    margin-top:6px;
    margin-left:5px;
  }
  .picked .addbtn{
    background: rgba(48, 97, 242, .2);
  }
  .flex-b-c>span{
    width:100px;
    height:28px;
    line-height:28px;
    background: var(--color-text-blue);
    color:#fff;
    font-size: 18px;
    font-family: 'PingFang-SC-Bold';
    text-align: center;
    border-radius:4px;
    cursor: default;
  }

/* 下 */
.foot_msg button{
    height:40px;
    line-height:40px;
    font-size:16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius:4px;
    border:none;
    width:200px;
    margin: calc((4.1vw - 40px) / 2) 0;
  }
  /* 弹出框 */
  ol{
    margin:15px 0;
  }
  .cont li.picked .addbtn{
    background: var(--color-text-blue);
  }
  .blue.hand{
    border:0;
    background:none;
  }
  .btn.cancle{
    background:transparent;
    color:#9D9FA6;
    font-size:16px;
  }
  .btn{
    background:var(--color-text-blue);
    color:#fff;
    width:100px;
    height:36px;
    line-height:36px;
    text-align: center;
    border-radius:4px;
    display: inline-block;
  }
</style>
