<template>
  <div class="pr">
    <div class="title_msg">
      <div class="f24 flex_1"><slot name="tit"></slot></div>
      <slot name="tit_right"></slot>
    </div>
    <div class="write_msg"><slot name="write_msg"></slot></div>
    <div class="foot_msg"><slot name="foot_msg"></slot></div>
    <!-- 弹出框 -->
    <slot name="the_popup"></slot>
  </div>
</template>

<script>
export default {
  name: 'RightSide'
}
</script>

<style scoped>
  .pr{
    background: url(@/assets/img/home/home_bg5.png) no-repeat left top/100% 100%;
    border-radius: 33px;
  }
  .pr::before{
    content:'';
    position:absolute;
    left:-2vw;
    top:4vh;
    width:80%;
    height:calc(100% - 8vh);
    background:rgba(48, 97, 242, .1);
    border-radius:40px;
    z-index:-1;
  }
  .pr::after{
    content:'';
    position: absolute;
    left: -4vw;
    top: 9vh;
    width: 6.66vw;
    height:6.6vw;
    background:url(@/assets/img/home/home_bg2.png) no-repeat left top/100%;
    z-index:-2;
  }
  /* 上 */
  .title_msg{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5.2vw;
    border-bottom: 1px solid #DADCE5;
  }
  .f24{
    line-height: 2.5;
  }
  /* 中间部分 */
  .write_msg{
    padding: 0 5.2vw;
    max-height: calc(100vh - 7.64vw - 96px);
    overflow: auto;
  }
  /* 下 */
  .foot_msg{
    background:#fff;
    padding:0 5.2vw;
    height: 4.1vw;
    border-radius:0 0 33px 33px;
    border:1px solid rgba(1,27,70,0.05);
    border-top:0;
    filter:drop-shadow(0px -1px 1px rgba(1,27,70,0.05));
  }
</style>
