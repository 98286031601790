<template>
  <div class="confirm" :class="isAdd?'show':'hide'">
    <div class="zhezhao"></div>
    <form class="content" action="">
      <div class="closeIcon" @click="close"><i class="icon-guanbi"></i></div>
      <div class="top" ><slot name="title"></slot></div>
      <div class="cont"><slot name="middle"></slot></div>
      <div class="footer flex-b-c">
        <slot name="footer"></slot>
      </div>
    </form>
  </div>
</template>

<script>
// import draggable from 'vuedraggable'
// import { ref } from 'vue'
export default {
  name: 'Popup',
  components: {},
  props: {
    isAdd: {
      type: Boolean
    }
  },
  setup (props, context) {
    let add = props.isAdd
    
    function close(){
      context.emit("changeEvent", false)
    }

    return {
      add, 
      close, 
    };
  },

}

</script>

<style scoped>
  .content{
    background: #fff;
    width: 46.875vw;
    position: fixed;
    top: 12.59vh;;
    left: calc(50% - 23.8375vw);
    border-radius: 20px;
    z-index: 9;
  }
  .closeIcon{
    position: absolute;
    top: -40px;
    right: -30px;
    color: var(--color-text-white);
  }
  .closeIcon i{
    font-size: 34px;
  }
  .top{
    font-size: 20px;
    font-weight: bold;
    line-height: 3em;
    margin: 0 1.25vw;
    border-bottom: 1px solid #DADCE5;
  }
  .cont{
    padding: 0 2.08vw;
    max-height: 54.25vh;
    overflow: auto;
  }
  .footer{
    height: 5.4vh;
    line-height: 5.4vh;
    min-height: 54px;
    background: var(--color-text-white);
    padding: 0 3.33vw;
    box-shadow: 0px -1px 1px 0px rgba(22,24,35,0.03);
    border-radius: 0px 0px 20px 20px;
  }
</style>