<template>
  <el-dialog v-model="dialogVisible" title="管理员工" @close="handleClose">
  <div style="padding: 10px">
    <el-button type="primary" @click="handleAdd">添加员工</el-button>
    <el-input type="text" v-model="searchObj.search_word" style="width: 300px; margin-left: 5px;" placeholder="请输入名称..." clearable>
      <template #append>
        <el-button type="primary" @click="searchEvent"><el-icon><Search /></el-icon></el-button>
      </template>
    </el-input>
    <div style="margin: 10px 0">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="staff_name" label="姓名" width="100"/>
        <el-table-column prop="staff_position" :formatter="formatter" label="职位" width="150"/>
        <el-table-column prop="staff_education" label="学历" width="80"/>
        <el-table-column prop="entry_time" label="入职时间" width="150"/>
        <el-table-column label="级别" align="center">
          <template #default="scope">
            <el-button link type="danger" v-if="(scope.row.is_head==1)">负责人</el-button>
            <el-button link type="primary" v-if="(scope.row.is_head==0)" @click="staff_updata(scope.row.staff_id, scope.row.department_id, 'is_head')">设为负责人</el-button>
            <el-button link type="danger" v-if="guandaiFun(scope.row, 1)">管理代表者</el-button>
            <el-button link type="primary" v-if="guandaiFun(scope.row, 0)" @click="staff_updata(scope.row.staff_id, scope.row.department_id, 'is_guandai')">设为管理代表者</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template #default="scope">
            <el-button link type="primary" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button link type="danger" @click.prevent="staffDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" title="员工信息" width="40%">
      <el-form ref="formRef" :model="staff_form" label-width="100px" style="padding-right:30px ">
        <el-form-item label="姓名">
          <el-input v-model="staff_form.staff_name" prop="staff_name" placeholder="姓名" autocomplete="off" style="width: 150px;"/>
          <div class="red">&nbsp;*</div>
        </el-form-item>
        <el-form-item label="职位">
          <el-input v-model="staff_form.staff_position" prop="staff_position" placeholder="职位" autocomplete="off" style="width: 150px;"/>
          <div class="red">&nbsp;*&nbsp;&nbsp;&nbsp;注：职位手动填写，如经理、员工、财务、切割工等。</div>
        </el-form-item>
        <el-form-item  label="性别">
          <el-select  v-model="staff_form.staff_gender" prop="staff_gender" placeholder="请选择" class="province" style="width: 165px;">
            <el-option :label="'男'" :value="1"></el-option>
            <el-option :label="'女'" :value="2"></el-option>
            <el-option :label="'保密'" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="学历">
          <el-select  v-model="staff_form.staff_education" prop="staff_education" placeholder="请选择" class="province" style="width: 165px;">
            <el-option  label="小学" value="小学"  ></el-option>
            <el-option  label="初中" value="初中"  ></el-option>
            <el-option  label="高中" value="高中"  ></el-option>
            <el-option  label="专科" value="专科"  ></el-option>
            <el-option  label="本科" value="本科"  ></el-option>
            <el-option  label="研究生" value="研究生"  ></el-option>
            <el-option  label="博士" value="博士"  ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="入职时间">
          <el-date-picker
              v-model="staff_form.entry_time" prop="entry_time"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
<!--        <el-form-item label="电话">
          <el-input v-model="staff_form.mobile" prop="mobile" autocomplete="off" style="width: 150px;"/>
          <div class="red">&nbsp;&nbsp;&nbsp;注：此项非必填，总经理的电话必须填写。</div>
        </el-form-item>
        <el-form-item label="级别">
          <el-checkbox v-model="staff_form.is_head" label="是否负责人" size="large" />
          <el-checkbox v-model="staff_form.is_guandai" label="管理代表者" size="large" />
        </el-form-item>-->
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
  </el-dialog>
</template>
<script>
import {getCurrentInstance, onMounted, ref, watch, reactive} from 'vue'
export default {
  props: {
    staffDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    const dialogVisible = ref(false);
    const dialogFormVisible = ref(false);
    let row = ref({});
    let tableData = ref([]);
    const staff_form = ref({});
    let dataState = reactive({
      company_id: '',
      department_id: ''
    });
    // 查询输入框初始值
    const searchObj = reactive({
      search_word:''
    });
    // 查询事件
    const searchEvent=()=>{
      getStaffList(dataState.company_id, dataState.department_id);
    }
    onMounted(()=>{
    })

    //
    const guandaiFun = (row, flag) => {
      if (row.is_manager==1) {
        return false;
      }else{
        if (row.is_manager==0 && row.is_guandai==1 && flag==1) return true;
        if (row.is_manager==0 && row.is_guandai==0 && flag==0) return true;
      }
    }

    //获取人员列表
    const getStaffList = (company_id,department_id) =>{
      proxy.$post("/department/staff_list",{
        //page:currentPage.value,
        company_id:company_id,department_id:department_id,search_word:searchObj.search_word
      }).then((response)=>{
        tableData.value = response.data.data;
      })
    }

    const handleClose = () => {
      ctx.emit("getDepartList");
      //ctx.emit('resetPercent');
      //console.log('close');
    };

    //添加人员
    const handleAdd = ()=>{
      //重置表单
      staff_form.value = {};
      dialogFormVisible.value = true;
      staff_form.value.is_head = 0;
      staff_form.value.is_guandai = 0
    }

    //编辑人员
    const handleEdit = (row)=>{
      staff_form.value = row;
      /*if (row.is_head==1){
        staff_form.value.is_head = true;
      }
      if (row.is_guandai==1){
        staff_form.value.is_guandai = true;
      }*/
      dialogFormVisible.value = true;
    }

    // 保存员工信息
    const handleSave = ()=>{
      if(!staff_form.value.staff_name){
        Api.msg('请填写员工姓名！', 'error', 50);
        return false;
      }
      if(!staff_form.value.staff_position){
        Api.msg('请填写员工职位！', 'error', 50);
        return false;
      }
      /*if(!staff_form.value.staff_education){
        Api.msg('请选择员工学历！', 'error', 50);
        return false;
      }
      if(!staff_form.value.entry_time){
        Api.msg('请选择入职时间！', 'error', 50);
        return false;
      }*/
      /*if(!staff_form.value.mobile){
        Api.msg('请填写员工的联系方式！', 'error', 50);
        return false;
      }*/
      staff_form.value.company_id = dataState.company_id;
      staff_form.value.department_id = dataState.department_id;
      proxy.$post('department/saveDepartmentStaff',staff_form.value).then((res)=>{
        if(res.code==0||res.code==200){
          getStaffList(dataState.company_id,dataState.department_id);
          Api.msg(res.msg,'success', 50,function(){
            dialogFormVisible.value = false;
          });
        }else{
          Api.msg(res.msg,'error', 50,function(){
            dialogFormVisible.value = true;
          });
        }

      });

    }

    // 删除员工信息
    const staffDel = (row)=>{
      Api.confirm('是否删除此员工?',()=>{
        proxy.$post('department/staffDel',{
          staff_id:row.staff_id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getStaffList(dataState.company_id,dataState.department_id);
          }
          Api.msg(res.msg);
        });
      });
    }

    //更新员工级别
    const staff_updata = (staff_id,department_id,flag)=>{
      proxy.$post('department/staff_updata',{
        staff_id:staff_id,department_id:department_id,flag:flag
      }).then((res)=> {
        if (res.code == 0) {
          getStaffList(dataState.company_id, dataState.department_id);
        }
      });
    }

    watch(() => props.staffDialogVisible, (val) => {
      //console.log(props.staffDialogVisible, val);
      dialogVisible.value = val
    });
    //子组件定义了一个方法用来初始化数据
    function initData(company_id, department_id) {
      dataState.company_id = company_id;
      dataState.department_id = department_id;
    }
    // 格式化职位
    function formatter(row){
      if(row.is_guandai==1){
        return row.staff_position+'/管理代表者'
      }else{
        return row.staff_position
      }
    }
    return {
      dialogVisible,dialogFormVisible,staff_form,row,tableData,formatter,
      handleAdd,handleEdit,getStaffList,initData,handleSave,staff_updata,
      handleClose,staffDel,searchObj,searchEvent,guandaiFun
    }
  }
}
</script>

<style scoped>

</style>
