<template>
  <div>
    <!-- 顶部 头 -->
    <Top>
      <template #title><div class="f20 bold">项目管理</div></template>
      <template #right>
        <!-- <router-link to="/login"> -->
          <el-button class="f20 bold" @click="logout">
            <i class="icon-tuichu mr-10"></i> 退出
          </el-button>
        <!-- </router-link> -->
      </template>
    </Top>
    <!-- 内容 -->
    <div>
      <div class="w1200 pt-20">
        <div class="add f16 mb-40 hand" @click="addEnterprise"><i class="icon-tianjia"></i> 添加项目</div>
        <!-- 已有企业 -->
        <div class="flex_wrap">
          <div class="itemBar pr text_center" v-for="(item, index) in defalultData.list" :key="index">
            <div @click="itemClick(item.id)">
              <div class="DIN blue bold">{{item.brief_name}}</div>
              <div class="f16 mt-15">{{item.project_name}}</div><!--{{item.company_name?item.company_name:item.project_name}}-->
              <!-- <slot name="industry_name"></slot> -->

              <div class="f14 mt-40">{{item.industry_name}}</div>
              <div class="flex-c- mt-15"><span>{{item.cer_name}}</span></div>
              <div class="mt-20 flex-c-c color_f">
                <el-progress :percentage="item.percent" :color="'customColor'"></el-progress>
              </div>
              <div v-if="item.status==0" class="pab red">制作中</div>
              <div v-if="item.status==4" class="pab" style="color: #0aa70a;">制作完成</div>
              <div v-if="item.status==1" class="pab red">审核中</div>
              <div v-if="item.status==2" class="pab red">审核通过</div>
              <div v-if="item.status==3" class="pab red">未通过</div>
            </div>
            <div @click="delProject(item.id)" class="red" style="position: absolute;top: 0;right: 0;cursor: pointer;padding: 10px;">删除</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增企业弹出层 -->
    <Popup :isAdd="isAdd" @changeEvent="changeIsAdd">
      <!-- <form action=""> -->
        <template v-slot:title>添加项目</template>
        <template v-slot:middle>
          <div class="red">注：请慎重填写，保存后不可更改！</div>
          <!-- <div class="flex">
            <span>企业名称</span>
            <div class="text">
              <el-input v-model="name" placeholder="请输入企业名称"></el-input>
            </div>
          </div> -->
          <!-- <div class="flex">
            <span>企业简称</span>
            <div class="text">
              <el-input v-model="shortname" placeholder="请输入企业简称"></el-input>
              <div class="red">注：企业名称简称写英文字母；如：河北证优科技有限公司，简称为ZYKJ。</div>
            </div>
          </div> -->
          <!-- <div class="flex">
            <span>所属区域</span>
            <div class="text">
              <Linkage first_id="province_id" second_id="city_id" country_id="country_id"  length="3" @changeEvent="changeArea">
                <div class="fill"></div>
              </Linkage>
            </div>
          </div> -->

          <!-- <div class="flex">
            <span>所属行业</span>
            <div class="text">
              <Linkage first_id="industrys_pid" second_id="industrys_id" type="industrys"  length="2" @changeEvent="changeIndustrys">
                <div class="fill"></div>
              </Linkage>
            </div>
          </div> -->
          <div class="flex">
            <span>项目名称</span>
            <div class="text">
              <el-input v-model="name" placeholder="请输入项目名称"></el-input>
              <!-- <div class="red">注：企业名称简称写英文字母；如：河北证优科技有限公司，简称为ZYKJ。</div> -->
            </div>
          </div>
          <div class="flex">
            <span>认证机构</span>
            <div class="text">
              <Linkage :first_id="org_id" type="Organization"  length="1" @changeEvent="changeOrganization"></Linkage>
              <div class="red">此处将影响多场所模版的内容，请确定认证机构后再进行选择，选择后将无法更改。</div>
            </div>
          </div>
          <div class="flex">
            <span>认证项目</span>
            <div class="text">
              <Linkage :first_id="category_id" :second_id="cer_id" type="certificate_category"  length="2" @changeEvent="changeCer"></Linkage>
            </div>
          </div>
          <div class="flex">
            <span>认证类型</span>
            <div class="text">
              <Linkage :first_id="industry_id" type="industry"  length="1" @changeEvent="changeIndustry"></Linkage>
              <div class="red">此处将影响手册模版的内容，请确定认证范围后再进行选择，选择后将无法更改。</div>
            </div>
          </div>
<!--          <div class="flex">
            <span style="width: 100px;">体系运行时间</span>
            <div class="text">
              <el-date-picker
                  v-model="iso_date"
                  type="date"
                  placeholder="体系运行时间"
                  value-format="YYYY-MM-DD"
                  style="width:150px;"
              />
            </div>
          </div>-->
        </template>
        <template v-slot:footer>
          <button type="reset" class="blue hand">清空内容</button>
          <div>
            <span class="btn cancle" @click="cancelAdd">取消</span>
            <span class="btn" @click="saveCompany">确定</span>
          </div>
        </template>
      <!-- </form> -->
    </Popup>
  </div>
</template>

<script>
import { ref , reactive , getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Top from '@/components/common/Top.vue'
import Popup from '@/components/common/Popup.vue'
import Linkage from '@/components/common/Linkage.vue'
import { layer } from "@layui/layer-vue";
// import mapData from "@/assets/mapData/map.json"

export default {
  name:'Change',
  components:{
    Top,
    Popup,
    Linkage,
  },
  props:{
    selProvince: String,
    selCity: String
  },
  setup(){
    const route = useRoute()// eslint-disable-line no-unused-vars
    const router = useRouter()
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    let customColor = ref('#fff')
    let isAdd = ref(false)
    const defalultData = reactive({
      list:[],
    });
    //地区
    let province_id = ref(0);
    let city_id = ref(0);
    let country_id = ref(0);
    const changeArea = (data)=>{// eslint-disable-line no-unused-vars
      province_id.value = data[0]||0
      city_id.value = data[1]||0
      country_id.value = data[2]||0
    }
    //行业
    let industrys_pid = ref(0);
    let industrys_id = ref(0);
    const changeIndustrys = (data)=>{// eslint-disable-line no-unused-vars
      industrys_pid.value = data[0]||0
      industrys_id.value = data[1]||0
    }
    //企业类型
    let industry_id = ref(0);
    const changeIndustry = (data)=>{// eslint-disable-line no-unused-vars
      industry_id.value = data[0]||0
    }
    //证书
    let category_id = ref(0);
    let cer_id = ref(0);
    const changeCer = (data)=>{// eslint-disable-line no-unused-vars
      // console.log(data)
      category_id.value = data[0]||0
      cer_id.value = data[1]||0
      // console.log(data)
    }
    //认证机构
    let org_id = ref(0);
    const changeOrganization = (data)=>{// eslint-disable-line no-unused-vars
      org_id.value = data[0]||0
    }
    let name = ref('')
    let shortname = ref('')

    //获取项目列表
    const getProjectList = ()=> {
      proxy.$post('project/list',{

      }).then(function (response) {
        defalultData.list = response.data;
      }).catch(function (error) {
        console.log(error);
      });
    }
    getProjectList();
    //退出登录
    const logout = ()=>{
      Api.removeItem('user_id')
      Api.removeItem('project_id')
      router.push('/login')
    }
    //添加企业显示
    const addEnterprise = ()=>{
      isAdd.value = !isAdd.value
    }
    //取消添加企业
    const cancelAdd = ()=>{
      isAdd.value = false
    }
    const changeIsAdd = (n)=>{
      isAdd.value = n
    }
    //执行添加企业 icon:1 对√ 2X 3？4锁5哭6笑
    const saveCompany = ()=>{
      // var company_name = name.value;
      // if(!company_name){
      //   layer.msg('请输入企业名称！');
      //   return false;
      // }
      var project_name = name.value;
      if(!project_name){
        layer.msg('请输入项目名称！');
        return false;
      }
      // var brief_name = shortname.value;
      // if(!brief_name){
      //   layer.msg('请输入企业简称！');
      //   return false;
      // }
      // if(!city_id){
      //   layer.msg('请选择所属区域！');
      //   return false;
      // }
      if(!cer_id.value){
        layer.msg('请选择认证项目！');
        return false;
      }
      if(!industry_id.value){
        layer.msg('请选择项目类型！');
        return false;
      }
      proxy.$post('/project/add',{
        project_name:project_name,
        // company_name:company_name.value,
        // brief_name:brief_name.value,
        // province:province_id.value,
        // city:city_id.value,
        cer_id:cer_id.value,
        // county:country_id.value,
        industry_id:industry_id.value,
        // industrys_id:industrys_id.value
      }).then(function (response) {
        if(response.code==200||response.code==0){
          layer.msg(response.msg,{icon:1,time:2000});
          getProjectList();
          cancelAdd();
        }else{
          layer.msg(response.msg,{icon:2,time:2000});
        }
      }).catch(function (error){
        console.log(error);
      });
    }
    //切换项目
    const itemClick = (project_id)=>{
      proxy.$post('project/change',{
        project_id:project_id,
      }).then(function (response) {
        if(response.code==200||response.code==0){
          // 更新会员信息
          Api.sessionSet('project_id',project_id);
          Api.storageSet('project_id',project_id,3600)
          router.push('/home');
        }else{
          layer.msg(response.msg,{icon:2,time:2000});
        }
      }).catch(function (error){
        console.log(error);
      });
    }
    //删除项目
    const delProject = (project_id)=>{
      Api.confirm('你确定要删除此项目吗？',()=>{
        proxy.$post('project/delProject',{
          project_id:project_id
        }).then(response=>{
          Api.msg(response.msg,0,'',()=>{
            if(response.code==0||response.code==200){
              getProjectList()
            }
          })
        })
      })
    }

    return {
      province_id,city_id,country_id,industrys_pid,industrys_id,industry_id,defalultData,category_id,cer_id,
      customColor, isAdd, logout,changeArea,changeCer,changeIndustrys,changeIndustry,delProject,
      name, shortname,changeOrganization,
      addEnterprise, changeIsAdd, cancelAdd, saveCompany, itemClick,
    }
  },
}
</script>

<style scoped>
  :deep(.el-header),
  :deep(.el-main){
    padding:0;
  }
  .head{
    height:60px;
    line-height:60px;
    background:#fff;
    box-shadow:0 1px 1px 0 rgba(22,24,35,.1);
  }
  :deep(.el-button){
    font-size: 14px;
    background: #EBEDF5;
  }
  .add{
    background: #3061F2;
    color:#fff;
    border-radius:4px;
    height:40px;
    line-height: 40px;
    padding:0 15px;
    display: inline-block;
  }

  .flex-c- span{
    background: #fff;
    border-radius:4px;
    padding:3px;
    color:#3061F2;
    font-size:14px;
    margin-right:5px;
  }

  /* 弹出层样式 */
  .flex{
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .flex>span{
    font-size: 14px;
    color: #6D6E73;
    margin-right: 10px;
    line-height: 32px;
  }
  .text{
    width: calc(100% - 4em - 10px);
    font-size: 14px;
  }
  :deep(.el-input){
    width:50%;
  }
  :deep(.el-input.el-input--suffix){
    width: 100%;
  }
  button.blue{
    background: transparent;
    border: none;
    font-size: 0.933vw;
  }
  .btn{
    display: inline-block;
    font-size: 14px;
    background: var(--color-text-blue);
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    width: 70px;
    text-align: center;
    color: var(--color-text-white);
    margin-left: 10px;
    cursor: pointer;
  }
  .btn.cancle{
    background: transparent;
    color: #9D9FA6;
    border: 1px #8c939d solid;
    border-radius: 4px;
  }




  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

    .itemBar{
    background: url('@/assets/img/change/bg.png') no-repeat top center/100%;
    width:calc(25% - 12px);
    padding-top:24px;
    margin-bottom: 15px;
    border-radius:10px;
  }
  .itemBar + .itemBar{
    margin-left:15px;
  }
  .itemBar:nth-child(4n+1){
    margin-left:0;
  }
  .itemBar .f14{
    color: #F29130;
  }
  .flex-c- span{
    background: #fff;
    border-radius:4px;
    padding:3px;
    color:#3061F2;
    font-size:14px;
    margin-right:5px;
  }
  .flex-c-c.color_f{
    width:50%;
    margin:15px auto 0;
    height: 22px;
  }
  :deep(.el-progress){
    width: 100%;
  }
  :deep(.el-progress-bar__outer){
    background: rgba(255,255,255,.2);
  }
  :deep(.el-progress__text) span{
    color:#fff;
    font-size:12px;
  }
  .itemBar .pab{
    top:0;
    left:0;
    background:rgba(48, 97, 242, .1);
    border-radius:10px 0 10px 0;
    font-size:14px;
    width:80px;
    height:20px;
    text-align: center;
  }
</style>
